import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Route, Routes } from 'react-router-dom';
import LayoutDoc from './components/organisms/Layout';
import Layout from './components/organisms/tailwind/Layout';
import ProjectSelection from './views/ProjectSelection';

import { useLogout } from './hooks/useLogout';
import { getCSRFToken } from './services/apiService';

import ProtectedRoute from './components/organisms/ProtectedRoute';
import DocumentOverview from './views/DocumentOverview';
import DocumentSelection from './views/DocumentSelection';
import Editor from './views/Editor';
import Login from './views/Login';
import RegisterInvitedUser from './views/RegisterInvitedUser';
import UserOverviewProject from './views/UserOverviewProject';
import VerifyUserAfterEmailInvite from './views/VerifyUserAfterEmailInvite';

import PublicRoute from './components/organisms/PublicRoute';
import IubendaCookieBanner from './components/organisms/tailwind/IubendaCookieBanner';
import useSystemPublicFeatures from './hooks/useSystemPublicFeatures';
import './translations/i18n';
import NotFoundPage from './views/NotFoundPage';
import ProjectSettings from './views/ProjectSettings';
import RegisterUser from './views/RegisterUser';
import ResetPassword from './views/ResetPassword';
import ResetPasswordRequestMail from './views/ResetPasswordRequestMail';
import UnauthorizedErrorPage from './views/UnauthorizedErrorPage';
import UserOverviewOrganization from './views/UserOverviewOrganization';
import UserSettings from './views/UserSettings';
import VerifyEmailSuccess from './views/VerifyEmailSuccess';
import VerifyUserAfterRegistration from './views/VerifyUserAfterRegistration';

const App = () => {
  const { initLogoutChannel } = useLogout();

  React.useEffect(() => {
    getCSRFToken();
  }, []);

  React.useEffect(() => {
    // Initializes a listener for logout events across all tabs
    initLogoutChannel();
  }, []);

  // This code removes the loading div in index.html after the app is loaded
  // useEffect is used to prevent a node that has already been removed (no longer in the DOM) from being attempted to be removed again.
  React.useEffect(() => {
    const loaderElement = document.querySelector('.loader-container');
    if (loaderElement) {
      loaderElement.remove();
    }
  }, []);

  const { availablePublicFeatures } = useSystemPublicFeatures();

  return (
    <div style={{ height: '100vh' }}>
      {availablePublicFeatures?.cookieBannerEnabled === true ? <IubendaCookieBanner /> : null}
      <Routes>
        <Route
          exact
          path="/invitation/:verifyEmailToken"
          element={
            <PublicRoute>
              <VerifyUserAfterEmailInvite />
            </PublicRoute>
          }
        />
        <Route
          exact
          path="/invitation/registration/:verifyEmailToken"
          element={
            <PublicRoute>
              <RegisterInvitedUser />
            </PublicRoute>
          }
        />
        <Route
          exact
          path="/registration"
          element={
            <PublicRoute>
              <RegisterUser />
            </PublicRoute>
          }
        />
        <Route
          exact
          path="/verify-email/:verifyEmailToken"
          element={
            <PublicRoute>
              <VerifyUserAfterRegistration />
            </PublicRoute>
          }
        />
        <Route
          exact
          path="/verify-email-success"
          element={
            <PublicRoute>
              <VerifyEmailSuccess />
            </PublicRoute>
          }
        />
        <Route
          exact
          path="/"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          exact
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          exact
          path="/unauthorized"
          element={
            <ProtectedRoute>
              <UnauthorizedErrorPage />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/forgotpassword"
          element={
            <PublicRoute>
              <ResetPasswordRequestMail />
            </PublicRoute>
          }
        />
        <Route
          exact
          path="/reset-password/:token"
          element={
            <PublicRoute>
              <ResetPassword />
            </PublicRoute>
          }
        />
        <Route
          exact
          path="/organizations/:organizationId/users"
          element={
            <ProtectedRoute redirection="/login">
              <Layout>
                <UserOverviewOrganization />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/organizations/:organizationId/projects"
          element={
            <ProtectedRoute redirection="/login">
              <Layout>
                <ProjectSelection />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/organizations/:organizationId/projects/:projectId"
          element={
            <ProtectedRoute redirection="/login">
              <Layout>
                <DocumentOverview />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/organizations/:organizationId/projects/:projectId/old"
          element={
            <ProtectedRoute redirection="/login">
              <LayoutDoc>
                <DocumentSelection />
              </LayoutDoc>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/organizations/:organizationId/projects/:projectId/users"
          element={
            <ProtectedRoute redirection="/login">
              <Layout>
                <UserOverviewProject />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/organizations/:organizationId/projects/:projectId/settings"
          element={
            <ProtectedRoute redirection="/login">
              <Layout>
                <ProjectSettings />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/organizations/:organizationId/projects/:projectId/document/:documentId"
          element={
            <ProtectedRoute redirection="/login">
              <LayoutDoc>
                <Editor />
              </LayoutDoc>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/organizations/:organizationId/users/:userId/settings"
          element={
            <ProtectedRoute redirection="/login">
              <Layout>
                <UserSettings />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route exact path="/terms-and-conditions" element={<p>General Terms and Conditions</p>} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};

export default App;
