import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import {
  deleteDocument,
  deleteDocuments,
  disablePreprocessing,
  enablePreprocessing,
  fetchDocuments,
  getAnonymizedDownload,
  postAnonymizedDownload,
  priorizePreprocessing,
  putArchive,
  putFinalize,
  putUnarchive,
  putUnfinalize,
  resetDocument,
  uploadDocument,
} from '../api/document';
import { getFilenameFromContentDispositionHeader, saveFile } from '../services/utils';

const initialState = {
  data: [],
  activeDocumentId: '',
  status: '',
  error: '',
};

/**
 * Fetches the documents
 */
export const fetchDocumentsDB = createAsyncThunk(
  'documents/fetchDocuments',
  async (options) => {
    const response = await fetchDocuments(
      options
    );
    return response.data;
  },
);

/**
 * Uploads a document to the db
 */
export const uploadDocumentDB = createAsyncThunk(
  'documents/uploadDocument',
  async ({ projectId, formData }) => {
    const response = await uploadDocument(projectId, formData);
    return response.data;
  },
);

/**
 * Resets a document in the db
 */
export const resetDocumentDB = createAsyncThunk(
  'documents/resetDocument',
  async ({ projectId, documentId }) => {
    const response = await resetDocument(projectId, documentId);
    return response.data;
  },
);

/**
 * Deletes a document from the db
 */
export const removeDocument = createAsyncThunk(
  'documents/deleteDocument',
  async ({ projectId, documentId }) => {
    const response = await deleteDocument(projectId, documentId);
    return response.data;
  },
);

/**
 * Deletes a list of documents from the db
 */
export const removeDocuments = createAsyncThunk(
  'document/deleteDocuments',
  async ({ projectId, documentIds }) => {
    const payload = documentIds;
    const response = await deleteDocuments(projectId, payload);
    return response.data;
  },
);

/**
 * Priorizes the preprocessing of a docuemnt in the db
 */
export const priorizePreprocessingDB = createAsyncThunk(
  'documents/priorizePreprocessing',
  async ({ projectId, documentId }) => {
    const response = await priorizePreprocessing(projectId, documentId);
    return response.data;
  },
);

/**
 * Enables the preprocessing of a docuemnt in the db
 */
export const enablePreprocessingDB = createAsyncThunk(
  'documents/enablePreprocessing',
  async ({ projectId, documentId }) => {
    const response = await enablePreprocessing(projectId, documentId);
    return response.data;
  },
);

/**
 * Disables the preprocessing of a docuemnt in the db
 */
export const disablePreprocessingDB = createAsyncThunk(
  'documents/disablePreprocessing',
  async ({ projectId, documentId }) => {
    const response = await disablePreprocessing(projectId, documentId);
    return response.data;
  },
);

/**
 * Set the is_finalize state of document.
 */
export const setFinalized = createAsyncThunk(
  'documents/setFinalized',
  async ({ projectId, documentId }) => {
    const response = await putFinalize(projectId, documentId);
    return response.data;
  },
);

/**
 * Sets the state of document to unfinalized.
 */
export const setUnfinalized = createAsyncThunk(
  'documents/setUnfinalized',
  async ({ projectId, documentId }) => {
    const response = await putUnfinalize(projectId, documentId, false);
    return response.data;
  },
);

/**
 * Sets the state of document to archived.
 */
export const setArchived = createAsyncThunk(
  'documents/setArchived',
  async ({ projectId, documentId }) => {
    const response = await putArchive(projectId, documentId);
    return response.data;
  },
);

/**
 * Sets the state of document to unarchived.
 */
export const setUnarchived = createAsyncThunk(
  'documents/setUnarchive',
  async ({ projectId, documentId }) => {
    const response = await putUnarchive(projectId, documentId);
    return response.data;
  },
);

/**
 * The slice of the documents state
 */
export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    /**
     * Clears the documents state by setting it to the initial state
     */
    clearDocuments() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDocumentsDB.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchDocumentsDB.fulfilled, (state, action) => {
        return { ...state, status: 'succeeded', data: action.payload };
      })
      .addCase(fetchDocumentsDB.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

// TODO: Outsource headerLine and filename - generalize download functionality

/**
 * Handles the download of a document with given id
 * @param {number} documentId the document id
 */
export async function downloadAnonymizedDocument(projectId, documentId) {
  return getAnonymizedDownload(projectId, documentId).then((response) => {
    // get filename
    const headerLine = response.headers['content-disposition'];
    const filename = getFilenameFromContentDispositionHeader(headerLine);
    // get content
    const blob = new Blob([response.data]);
    // open save dialog
    saveAs(blob, filename);
  });
}

/**
 * Handles the download of the documents with given ids in a single zipped file.
 * @param {number} documentIds the list of document ids
 */
export async function downloadAnonymizedDocuments(projectId, documentIds) {
  if (documentIds.length === 1) {
    return getAnonymizedDownload(projectId, documentIds[0]).then((response) => {
      const headerLine = response.headers['content-disposition'];
      saveFile(response.data, headerLine);
    });
  }
  return postAnonymizedDownload(projectId, documentIds).then((response) => {
    const headerLine = response.headers['content-disposition'];
    saveFile(response.data, headerLine);
  });
}

export const selectDocuments = createSelector(
  (state) => state.documents.data,
  (documents) => documents.content,
);
export const selectDocumentsTotalElements = createSelector(
  (state) => state.documents.data,
  (documents) => documents.totalElements,
);
export const selectDocumentsPage = createSelector(
  (state) => state.documents.data,
  (data) => data.pageable?.pageNumber || null,
);
export const selectDocumentsOffset = createSelector(
  (state) => state.documents.data,
  (data) => data.pageable?.offset || null,
);
export const selectDocumentFirstPage = createSelector(
  (state) => state.documents.data,
  (data) => data.first,
);
export const selectDocumentsStatus = createSelector(
  (state) => state.documents,
  (documents) => documents.status,
);
export const selectDocumentsError = createSelector(
  (state) => state.documents,
  (documents) => documents.error,
);

export const { clearDocuments } = documentsSlice.actions;

export default documentsSlice.reducer;
