import React from 'react';
import useAnonymizedDocument from '../../../../../hooks/useAnonymizedDocument';
import documentTypes from '../../../../../types/documentTypes';
import CustomDialog from '../../../../molecules/tailwind/CustomDialog';
import ImagePreview from './ImagePreview';
import PdfPreviewWithZoomControls from './PdfPreviewWithZoomControls';

/**
 * Dialog component that displays a preview of the document
 *
 * @param {boolean} open Whether the modal is open
 * @param {string} documentType The document type
 * @param {string} documentName The document name
 * @param {string} projectId The project id
 * @param {string} documentId The document id
 * @param {Function} onClose Function to close the modal
 */
const PreviewDialog = ({ open, documentType, documentName, projectId, documentId, onClose }) => {
  const { anonymizedDocumentBase64, isBase64Loading } = useAnonymizedDocument(
    projectId,
    documentId,
  );

  const renderPreview = () => {
    switch (documentType) {
      case documentTypes.PDF:
      case documentTypes.DOCX:
      case documentTypes.DOC:
        return (
          <PdfPreviewWithZoomControls
            documentBase64={anonymizedDocumentBase64}
            isDocumentLoading={isBase64Loading}
          />
        );
      case documentTypes.IMAGE:
        return (
          <ImagePreview imageBase64={anonymizedDocumentBase64} isImageLoading={isBase64Loading} />
        );
      default:
        return null;
    }
  };

  return (
    <CustomDialog isOpen={open} onClose={onClose} isDialogCloseButton size="medium">
      <div className="p-2">
        <p className="text-3xl font-bold text-blue-2">{documentName}</p>
        <div className="flex flex-col justify-center">{renderPreview()}</div>
      </div>
    </CustomDialog>
  );
};

export default PreviewDialog;
