import React from 'react';
import { useTranslation } from 'react-i18next';

import { getBaseURL } from '../../../api/axios';
import doccapeLogo from '../../../assets/DocCape_Favicon.svg';
import useSystemPublicFeatures from '../../../hooks/useSystemPublicFeatures';
import useSystemVersion from '../../../hooks/useSystemVersion';
import Footer from '../../molecules/tailwind/Footer';
import Navbar from '../../molecules/tailwind/Navbar';
import Overlay from '../../molecules/tailwind/Overlay';
import SubscriptionPlans from '../../molecules/tailwind/SubscriptionPlans';

const Layout = (props) => {
  const { children } = props;
  const [showSubscriptionPlans, setShowSubscriptionPlans] = React.useState(false);

  const { t } = useTranslation();

  const { backendVersion } = useSystemVersion();
  const { availablePublicFeatures } = useSystemPublicFeatures();

  /**
   * Footer items (links) for the footer of the login page
   */
  const footerItems = [
    /* { name: t('footer.termsAndConditions'), href: `https://doccape.de/agb` }, */
    { name: t('footer.privacyNotice'), href: `https://doccape.de/datenschutz` },
    { name: t('footer.apiDocs'), href: `${getBaseURL()}/docs` },
    { name: t('footer.website'), href: 'https://doccape.de/' },
  ];

  const logoImage = (
    <img className="h-10 w-10 rounded-full bg-white" src={doccapeLogo} alt="doccape logo" />
  );

  return (
    <div className="h-full">
      <Navbar setShowSubscriptionPlans={setShowSubscriptionPlans} />
      {children}
      <Footer
        items={footerItems}
        image={logoImage}
        backendVersion={backendVersion}
        availableFeatures={availablePublicFeatures}
      />
      <Overlay
        show={showSubscriptionPlans}
        onClose={() => {
          setShowSubscriptionPlans(false);
        }}
      >
        <SubscriptionPlans
          onClose={() => {
            setShowSubscriptionPlans(false);
          }}
        />
      </Overlay>
    </div>
  );
};

export default Layout;
