import { useEffect } from 'react';

/**
 * Displays the cookie banner so that the user can accept or reject all cookies or customize settings
 */
const CookieBanner = () => {
    const onActivationDone = () => {
        return null;
    };
    const onBannerClosed = () => {
        return null;
    };

    const onBannerShown = () => {
        return null;
    };

    const onBeforePreload = () => {
        return null;
    };

    const onConsentFirstGiven = () => {
        return null;
    };

    const onConsentFirstRejected = () => {
        return null;
    };

    const onConsentGiven = () => {
        return null;
    };

    const onConsentRead = () => {
        return null;
    };

    const onConsentRejected = () => {
        return null;
    };

    const onCookiePolicyShown = () => {
        return null;
    };

    const onError = (e) => {
        console.log('An Iubenda Error occured', e);
    };

    const onFatalError = (e) => {
        console.log('An Iubenda Error occured', e);
    };

    const onPreferenceExpressed = (data) => {
        // matomo is currently not used, therefore this code is commented out
        // if (data.purposes[4] === true) {
        //     window._paq.push(['setCookieConsentGiven']);
        // } else {
        //     window._paq.push(['forgetCookieConsentGiven']);
        // }
    };

    const onPreferenceExpressedOrNotNeeded = () => {
        return null;
    };

    const onPreferenceFirstExpressed = () => {
        return null;
    };

    const onReady = () => {
        return null;
    };

    const onStartupFailed = (e) => {
        console.log('An Iubenda Error occured', e);
    };

    const onPreferenceNotNeeded = () => {
        return null;
    };

    useEffect(() => {
        // Konfiguriere iubenda
        const iubendaConfigScript = document.createElement('script');
        iubendaConfigScript.type = 'text/javascript';
        iubendaConfigScript.innerHTML = `
    var _iub = _iub || [];
    _iub.csConfiguration = {"askConsentAtCookiePolicyUpdate":true,"floatingPreferencesButtonCaptionColor":"#0042B4","floatingPreferencesButtonColor":"#FFFFFF","floatingPreferencesButtonDisplay":"bottom-left","lang":"de","perPurposeConsent":true,"siteId":3017344,"whitelabel":false,"cookiePolicyId":21421181,"cookiePolicyUrl":"https://doccape.de/datenschutz/", "callback":{ "onActivationDone":${onActivationDone},"onBannerClosed":${onBannerClosed},"onBannerShown":${onBannerShown},"onBeforePreload":${onBeforePreload},"onConsentFirstGiven":${onConsentFirstGiven},"onConsentFirstRejected":${onConsentFirstRejected},"onConsentGiven":${onConsentGiven},"onConsentRead":${onConsentRead},"onConsentRejected":${onConsentRejected},"onCookiePolicyShown":${onCookiePolicyShown},"onError":${onError},"onFatalError":${onFatalError},"onPreferenceExpressed":${onPreferenceExpressed},"onPreferenceExpressedOrNotNeeded":${onPreferenceExpressedOrNotNeeded},"onPreferenceFirstExpressed":${onPreferenceFirstExpressed},"onPreferenceNotNeeded":${onPreferenceNotNeeded},"onReady":${onReady},"onStartupFailed":${onStartupFailed} }, "banner":{ "acceptButtonColor":"#0042B4","acceptButtonDisplay":true,"backgroundColor":"#FFFFFF","brandBackgroundColor":"#FFFFFF","brandTextColor":"#0042B4","closeButtonDisplay":false,"customizeButtonColor":"#A5A6A796","customizeButtonDisplay":true,"explicitWithdrawal":true,"fontSizeBody":"12px","listPurposes":true,"logo":"https://assets.scitlab.de/scitlab_logo_0522_sticker.png","position":"float-bottom-left","rejectButtonCaptionColor":"#FFFFFF","rejectButtonColor":"#0042B46E","rejectButtonDisplay":true,"showPurposesToggles":true,"textColor":"#022D99","rejectButtonCaption":"Nur notwendige Cookies akzeptieren" }};
    `;
        document.body.appendChild(iubendaConfigScript);

        // Füge das iubenda Auto-blocking-Skript hinzu
        const autoBlockingScript = document.createElement('script');
        autoBlockingScript.src = '/iubenda-auto-blocking.js';
        autoBlockingScript.type = 'text/javascript';
        document.body.appendChild(autoBlockingScript);

        // Füge das iubenda Cookie Solution-Skript hinzu
        const iubendaScript = document.createElement('script');
        iubendaScript.src = '//cdn.iubenda.com/cs/iubenda_cs.js';
        iubendaScript.type = 'text/javascript';
        iubendaScript.charset = 'UTF-8';
        iubendaScript.async = true;
        document.body.appendChild(iubendaScript);

        return () => {
            document.body.removeChild(iubendaConfigScript);
            document.body.removeChild(autoBlockingScript);
            document.body.removeChild(iubendaScript);
        };
    }, []);
};

export default CookieBanner;
