import { Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { classNames } from '../../../utils/tailwindUtils';

/**
 * Displays a menu button
 * @param {Array} menuItems - The items to display in the menu
 * @param {JSX.Element} button - The button to display
 * @param {string} menuPosition - The position of the menu (left or right)
 * @example
 * const menuItems = [
 *   {
 *      name: 'Item 1',
 *      onClick: () => console.log('Item 1 clicked'),
 *      disabled: false,
 *   },
 * ];
 * return <MenuButton menuItems={menuItems} button={<UserMenuButton name="John Doe" imageUrl={userImageUrl} />} />;
 */
const MenuButton = ({ menuItems, button, menuPosition = 'right' }) => {
  if (!Array.isArray(menuItems)) {
    console.error('Property menuItems must be an array for component MenuButton!');
    return null;
  }
  if (!button) {
    console.error('Property button is required for component MenuButton!');
    return null;
  }

  const menuPositionClass = menuPosition === 'right' ? 'left-0' : 'right-0';

  return (
    <Menu as="div" className="relative overflow-visible">
      {/* User icon and user name */}
      <div>
        <Menu.Button as={Fragment} className="flex">
          <div>{button}</div>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={classNames(
            `${menuPositionClass} overflow-visible absolute z-50 mt-2 w-48 origin-top-right rounded-md bg-white py-1 px-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`,
          )}
        >
          {menuItems.map((item) => (
            <Menu.Item
              key={item.name}
              className={classNames(
                item.disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
                'overflow-visible text-left text-base',
              )}
              disabled={item.disabled}
            >
              {({ active }) => (
                <button
                  type="button"
                  onClick={item.onClick}
                  className={`${
                    active ? 'bg-babyBlue-3 text-blue-2' : 'text-blue-2'
                  } group flex w-full items-center rounded-md px-2 py-1 text-base`}
                >
                  <div className="mr-4">{item.icon}</div>
                  {item.name}
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default MenuButton;
