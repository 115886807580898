import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AppToaster from '../components/molecules/AppToaster';
import {
  clearDocumentData,
  fetchDocumentData,
  selectDocumentData,
  selectDocumentDataError,
  selectDocumentDataStatus,
  selectDocumentName,
  selectDocumentType,
  selectLastFileModified,
  selectLastModified,
  selectProcessingStatus,
  updateDocumentData,
} from '../reducers/documentDataSlice';
import { setFinalized } from '../reducers/documentsSlice';
import { getDocumentDataUpdate } from '../services/documentDataService';

/**
 * Exposes callbacks to save the document, handles ui states
 * @param {*} saveTextData
 * @returns
 */
const useDocumentData = (projectId, documentId) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // ------------- SLICE ACCESS
  const sliceDocumentData = useSelector(selectDocumentData);
  const processingStatus = useSelector(selectProcessingStatus);
  const fetchStatus = useSelector(selectDocumentDataStatus); // TODO: change naming for all different slices
  const fetchError = useSelector(selectDocumentDataError);
  const documentType = useSelector(selectDocumentType);
  const documentName = useSelector(selectDocumentName);
  const documentLastModified = useSelector(selectLastModified);
  const documentLastFileModified = useSelector(selectLastFileModified);

  // State for indicating to the user whether the document is currently being compiled
  const [isCompiling, setIsCompiling] = useState(false);

  // ------------- STATES
  const [isSaving, setIsSaving] = useState(false);
  const [isFinalizing, setIsFinalizing] = useState(false);

  const initSlice = () => {
    dispatch(fetchDocumentData({ projectId, documentId }))
      .unwrap()
      .then(() => {});
  };

  useEffect(() => {
    // TODO: isLoading???
    initSlice(projectId, documentId);
    return () => {
      // Clears document data state when component unmounts
      dispatch(clearDocumentData());
    };
  }, [projectId, documentId]);

  const createSaveDataAndSave = async (
    paragraphs,
    annotations,
    sentenceStarts,
    sentenceStartIdsToRemove,
    images,
    setDidUserStartCompilation,
  ) => {
    const updatedDocumentData = getDocumentDataUpdate(
      sliceDocumentData,
      paragraphs,
      annotations,
      sentenceStarts,
      sentenceStartIdsToRemove,
      images,
    );
    setDidUserStartCompilation(true);

    await dispatch(updateDocumentData({ projectId, documentId, documentData: updatedDocumentData }))
      .unwrap()
      .then(() => {
        AppToaster({
          description: t('main.toastMessages.documentSaveSuccess'),
          status: 'success',
        });
      })
      .catch(() => {
        setIsCompiling(false);
        setDidUserStartCompilation(false);
      });
  };

  /**
   * Saves the document data to the backend
   *
   * @param {*} paragraphs
   * @param {*} annotations
   * @param {*} sentenceStartIdsToRemove
   * @param {*} sentenceStarts
   * @param {*} images
   * @param {*} setDidUserStartCompilation Callback to set the state that the user started the compilation
   */
  const saveDocument = async (
    paragraphs,
    annotations,
    sentenceStarts,
    sentenceStartIdsToRemove,
    images,
    setDidUserStartCompilation,
  ) => {
    setIsSaving(true);

    await createSaveDataAndSave(
      paragraphs,
      annotations,
      sentenceStarts,
      sentenceStartIdsToRemove,
      images,
      setDidUserStartCompilation,
    );
    setIsSaving(false);
  };

  /**
   * Finalizes the document
   *
   * @param {*} paragraphs
   * @param {*} annotations
   * @param {*} sentenceStarts
   * @param {*} sentenceStartIdsToRemove
   * @param {*} images
   * @param {*} setDidUserStartCompilation
   */
  const finalizeDocument = async (
    paragraphs,
    annotations,
    sentenceStarts,
    sentenceStartIdsToRemove,
    images,
    setDidUserStartCompilation,
  ) => {
    setIsFinalizing(true);
    await createSaveDataAndSave(
      paragraphs,
      annotations,
      sentenceStarts,
      sentenceStartIdsToRemove,
      images,
      setDidUserStartCompilation,
    );
    await dispatch(setFinalized({ projectId, documentId })).unwrap();
    setIsFinalizing(false);
  };

  /**
   * Compiles the document
   *
   * @param {*} paragraphs
   * @param {*} annotations
   * @param {*} sentenceStartIdsToRemove
   * @param {*} sentenceStarts
   * @param {*} images
   */
  const compileDocument = async (
    paragraphs,
    annotations,
    sentenceStarts,
    sentenceStartIdsToRemove,
    images,
  ) => {
    const updatedDocumentData = getDocumentDataUpdate(
      sliceDocumentData,
      paragraphs,
      annotations,
      sentenceStarts,
      sentenceStartIdsToRemove,
      images,
    );

    await dispatch(
      compileDocumentData({ projectId, documentId, documentData: updatedDocumentData }),
    ).unwrap();
  };

  return {
    isSaving,
    isFinalizing,
    isCompiling,
    processingStatus,
    documentType,
    documentName,
    documentLastModified,
    documentLastFileModified,
    saveDocument,
    setIsSaving,
    setIsCompiling,
    finalizeDocument,
  };
};

export default useDocumentData;
