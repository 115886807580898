import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../atoms/tailwind/Button';

/**
 * Footer for the table pagination (displays the current number of entries and the buttons to navigate between pages)
 * @param {number} pageNumber - The current page number
 * @param {boolean} isLastPage - Whether the current page is the last one
 * @param {boolean} isFirstPage - Whether the current page is the first one
 * @param {number} from - The first entry number
 * @param {number} to - The last entry number
 * @param {number} totalElements - The total number of entries
 * @param {boolean} isLoading - Whether the table is loading
 * @param {function} onChangePage - The function to call when the page is changed
 */
const TablePaginationFooter = ({
  pageNumber,
  isLastPage = false,
  isFirstPage = false,
  from,
  to,
  totalElements,
  isLoading,
  onChangePage,
}) => {
  const { t } = useTranslation();

  /**
   * Handles the clik in the last button
   */
  const onLast = () => {
    if (isFirstPage) return;
    onChangePage(pageNumber - 1);
  };

  /**
   * Handles the click in the next button
   */
  const onNext = () => {
    if (isLastPage) return;
    onChangePage(pageNumber + 1);
  };

  return (
    <div className="flex justify-between pt-2">
      <p>{t('tablePaginationFooter.locationInfo', { from, to, count: totalElements })}</p>
      <div className="flex justify-between gap-x-1">
        <div className="w-20">
          <Button
            buttonText={t('tablePaginationFooter.prev')}
            color="white"
            disabled={isFirstPage}
            buttonAction={onLast}
            isLoading={!isFirstPage && isLoading}
            fullWidth
          />
        </div>
        <div className="w-1" />
        <div className="w-20">
          <Button
            buttonText={t('tablePaginationFooter.next')}
            color="white"
            disabled={isLastPage}
            buttonAction={onNext}
            isLoading={!isLastPage && isLoading}
            fullWidth
          />
        </div>
      </div>
    </div>
  );
};

export default TablePaginationFooter;
