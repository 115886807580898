import { API } from './axios';

/**
 * Anonymize the given file by replacing the text passages specified in anonymizations.
 * The character indices in anonymizations refer to the file's plain text representation.
 * @param {Object} documentId The id of the document
 * @param {Object} payload The replacements
 * @returns Promise
 */
export const compileDocument = (documentId, payload) => {
  return API.post(`/documents/compile/${documentId}`, payload, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

/**
 * Download the anonmyized document.
 * @param {Object} formData The form data
 * @returns Promise
 */
export const getAnonymizedDownload = (projectId, documentId) => {
  return API.get(`/projects/${projectId}/documents/${documentId}/anonymized/download`, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
    responseType: 'blob',
  });
};

/**
 * Download multiple anonmyized document in a single zip file.
 * @param {Object} payload The form data
 * @returns Promise
 */
export const postAnonymizedDownload = (projectId, payload) => {
  return API.post(`/projects/${projectId}/documents/anonymized/download`, payload, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
    responseType: 'blob',
  });
};

/**
 * Creates ner and cr dataset from annotations and pseudonyms
 * @param {Object} documentId The document id
 * @returns Promise
 */
export const createTextDatasets = (documentId) => {
  return API.post(`/documents/create-text-datasets/${documentId}`, '', {
    withCredentials: true,
  });
};

/**
 * Creates datasets from the image annotations
 * @param {Object} documentId The document id
 * @returns Promise
 */
export const createImageDatasets = (documentId) => {
  return API.post(`/documents/create-image-datasets/${documentId}`, '', {
    withCredentials: true,
  });
};

/**
 * Fetches the documents of a given project from the database
 * @param {number} projectId - The id of the project
 * @param {string} name - The name of the document
 * @param {string} status - The status of the document
 * @param {string} fromDate - The lower bound of the date range in which the documents were edited
 * @param {string} toDate - The upper bound of the date range in which the documents were edited
 * @param {number} page - The page number
 * @param {number} size - The size of the page
 * @param {string[]} sort - The sort parameters
 * @param {[{}]} tagKeyValues - Each key-value pair corresponds to an assignment that describes a document
 * @returns Promise
 */
export const fetchDocuments = ({ projectId,
    name,
    status,
    fromDate,
    toDate,
    page,
    size,
    sort,
    tagKeyValues,
    type, 
  }) => {
  // Fetch the documents with a POST request because the search parameters are in the body
  return API.post(
    `projects/${projectId}/documents/search`,
    {
      name: name === '' ? undefined : name,
      status: status === '' ? undefined : status,
      from: fromDate,
      to: toDate,
      pageable: {
        page,
        size,
        sort,
      },
      tagKeyValues: tagKeyValues === '' ? undefined : tagKeyValues,
      type: type === '' ? undefined : type,
    },
    {
      withCredentials: true,
      headers: {
        accept: '*/*',
      },
    },
  );
};

/**
 * Uploads a document to a given project in the database
 * @param {*} formData
 * @returns
 */
export const uploadDocument = (projectId, formData) => {
  return API.post(`projects/${projectId}/documents`, formData, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

/**
 * Resets the document with the given in in the database
 *
 * @param {*} payload
 * @returns
 */
export const resetDocument = (projectId, documentId) => {
  return API.put(
    `/projects/${projectId}/documents/${documentId}/reset`,
    {},
    {
      withCredentials: true,
      headers: {
        accept: '*/*',
      },
    },
  );
};

/**
 * Deletes the document with the given id from the database
 * @param {*} documentId
 * @returns
 */
export const deleteDocument = (projectId, documentId) => {
  return API.delete(`/projects/${projectId}/documents/${documentId}`, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

/**
 * Deletes the documents with the given ids from the database
 * @param {List[number]} payload A List of document ids
 */
export const deleteDocuments = (projectId, payload) => {
  return API.delete(`/projects/${projectId}/documents`, {
    data: payload,
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

/**
 * Priorizes the preprocessing of the document with given id
 * @param {*} documentId
 * @returns
 */
export const priorizePreprocessing = (projectId, documentId) => {
  return API.post(
    `/projects/${projectId}/documents/${documentId}/priorize-preprocessing`,
    {},
    {
      withCredentials: true,
      headers: {
        accept: '*/*',
      },
    },
  );
};

/**
 * Enables preprocessing of the document with given id
 * @param {*} documentId
 * @returns
 */
export const enablePreprocessing = (projectId, documentId) => {
  return API.post(
    `/projects/${projectId}/documents/${documentId}/enable-preprocessing`,
    {},
    {
      withCredentials: true,
      headers: {
        accept: '*/*',
      },
    },
  );
};

/**
 * Disables preprocessing of the document with given id
 * @param {*} documentId
 * @returns
 */
export const disablePreprocessing = (projectId, documentId) => {
  return API.post(
    `/projects/${projectId}/documents/${documentId}/disable-preprocessing`,
    {},
    {
      withCredentials: true,
      headers: {
        accept: '*/*',
      },
    },
  );
};

/**
 * Sets the state of the document to finalized
 */
export const putFinalize = (projectId, documentId) => {
  return API.put(
    `projects/${projectId}/documents/${documentId}/finalize`,
    {},
    {
      withCredentials: true,
      headers: {
        accept: '*/*',
      },
    },
  );
};

/**
 * Sets the state of the document to unfinalized
 * @param {*} documentId The document id
 */
export const putUnfinalize = (projectId, documentId) => {
  return API.put(
    `projects/${projectId}/documents/${documentId}/unfinalize`,
    {},
    {
      withCredentials: true,
      headers: {
        accept: '*/*',
      },
    },
  );
};

/**
 * Sets the state of the document to archived
 */
export const putArchive = (projectId, documentId) => {
  return API.put(
    `projects/${projectId}/documents/${documentId}/archive`,
    {},
    {
      withCredentials: true,
      headers: {
        accept: '*/*',
      },
    },
  );
};

/**
 * Sets the state of the document to unarchived
 */
export const putUnarchive = (projectId, documentId) => {
  return API.put(
    `projects/${projectId}/documents/${documentId}/unarchive`,
    {},
    {
      withCredentials: true,
      headers: {
        accept: '*/*',
      },
    },
  );
};

/**
 * Saves the piis to the database
 */
export const setPiis = (projectId, documentId, payload) => {
  return API.post(`projects/${projectId}/documents/${documentId}/piis`, payload, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

/**
 * Load the document data (textData [tokens, personal identifiable information] and images) of the document with given id.
 * The character and token indices refer to the file's plain text representation.
 *
 * @param {Object} documentId The document id
 * @returns Promise
 */
export const getDocumentData = (projectId, documentId) => {
  return API.get(`projects/${projectId}/documents/${documentId}/piis`, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

export const putDocumentData = (projectId, documentId, payload) => {
  return API.put(`projects/${projectId}/documents/${documentId}/piis`, payload, {
    withCredentials: true,
    headers: {},
  });
};

/**
 * Fetches the anonymized document in base64 from the database
 *
 * @param {*} documentId
 * @returns
 */
export const getAnonymizedBase64 = (projectId, documentId) => {
  return API.get(`projects/${projectId}/documents/${documentId}/anonymized/base64`, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

/**
 * Fetches the original document in base64 from the database
 *
 * @param {*} documentId
 * @returns
 */
export const getOriginalBase64 = (projectId, documentId) => {
  return API.get(`projects/${projectId}/documents/${documentId}/original/base64`, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

/**
 * Retrieves the bas64 of the image with the given url
 *
 * @param {string} imageUrl The route to the image
 * @returns Promise
 */
export const getDocumentImage = (imageUrl) => {
  return API.get(imageUrl, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

/**
 * Updates database with all exist bounding boxes of images in a document
 *
 * @param {*} payload
 * @returns
 */
export const updateImageBboxesAPI = (documentId, payload) => {
  return API.put(`/documents/images/pii-regions/${documentId}`, payload, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

/**
 * Fetches the Image Anonymization labels from Database.
 *
 * @param {*} access
 * @returns
 */
export const fetchImageLabelsAPI = () => {
  return API.get('img-anonymization-labels', {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

/**
 * Updates the Image Anonymization Label Settings in the Database.
 *
 * @param {*} labelName the name of the label
 * @param {*} payload the updated settings
 * @param {*} access
 * @returns
 */
export const updateImageLabelSettingsAPI = (payload) => {
  return API.put(`img-anonymization-labels`, payload, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

/**
 * Get a summary of a specific document
 *
 * @param {*} projectId
 * @param {*} documentId
 * @returns summary of a document
 */
export const postDocumentSummary = (projectId, documentId) => {
  return API.post(`projects/${projectId}/documents/${documentId}/summary`, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

/**
 * Get the information of a document (status, filesize etc.)
 * @param {number} projectId
 * @param {number} documentId
 * @returns Promise
 */
export const getDocumentInfo = (projectId, documentId) => {
  return API.get(`projects/${projectId}/documents/${documentId}`, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};

/**
 * Get all document tags with values of a specific project
 */
export const getDocumentsTagsWithValuesByProject = (projectId) => {
  return API.get(`/projects/${projectId}/tags/values`, {
    withCredentials: true,
    headers: {
      accept: '*/*',
    },
  });
};
