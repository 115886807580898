import { Center, Spinner } from '@chakra-ui/react';
import React, { useLayoutEffect, useRef } from 'react';
import KonvaAnnotator from './KonvaAnnotator';

const ImageAnnotationControl = ({
  stageHeight,
  stageWidth,
  renderableBboxs,
  setStageHeight,
  setStageWidth,
  activeImage,
  setActiveLabel,
  isCreatingBbox,
  setIsCreatingBbox,
  isEditingBbox,
  isMovingImageButtonMode,
  activeLabel,
  activeBbox,
  setActiveBbox,
  scaleFactor,
  toStageFrame,
  toImageFrame,
  translationVector,
  initAnnotatorStage,
  updateBbox,
  addBbox,
  drawnKeyPoints,
  transformedKeyPoints,
  flattenedPoints,
  handlePointDragMove,
  handleMouseMove,
  pointDragBoundMove,
  setPressedMouseButton,
  handleZoomIntoMousePointer,
  computeDraggedKeyPoints,
  addKeyPoint,
  isPolyComplete,
  pointDragBoundFunc,
}) => {
  const ref = useRef(null);

  useLayoutEffect(() => {
    if (ref.current?.clientHeight && ref.current?.clientWidth) {
      setStageHeight(ref.current.clientHeight);
      setStageWidth(ref.current.clientWidth);
    }
  }, []);

  return (
    <Center bg="white" width="100%" height="100%" ref={ref}>
      {activeImage === null ? (
        <Center>
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.600" size="xl" />
        </Center>
      ) : (
        <KonvaAnnotator
          imageBytes={activeImage.base64}
          stageHeight={stageHeight}
          stageWidth={stageWidth}
          renderableBboxs={renderableBboxs}
          activeBbox={activeBbox}
          setActiveLabel={setActiveLabel}
          setActiveBbox={setActiveBbox}
          toStageFrame={toStageFrame}
          translationVector={translationVector}
          scaleFactor={scaleFactor}
          toImageeFrame={toImageFrame}
          initAnnotatorStage={initAnnotatorStage}
          activeLabel={activeLabel}
          isCreatingBbox={isCreatingBbox}
          setIsCreatingBbox={setIsCreatingBbox}
          isEditingBbox={isEditingBbox}
          isMovingImageButtonMode={isMovingImageButtonMode}
          updateBbox={updateBbox}
          addBbox={addBbox}
          drawnKeyPoints={drawnKeyPoints}
          transformedKeyPoints={transformedKeyPoints}
          flattenedPoints={flattenedPoints}
          handlePointDragMove={handlePointDragMove}
          handleMouseMove={handleMouseMove}
          pointDragBoundMove={pointDragBoundMove}
          setPressedMouseButton={setPressedMouseButton}
          handleZoomIntoMousePointer={handleZoomIntoMousePointer}
          computeDraggedKeyPoints={computeDraggedKeyPoints}
          addKeyPoint={addKeyPoint}
          isPolyComplete={isPolyComplete}
          pointDragBoundFunc={pointDragBoundFunc}
        />
      )}
    </Center>
  );
};

export default ImageAnnotationControl;
