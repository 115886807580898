import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  clearOriginalDocument,
  fetchOriginalDocument,
  selectOriginalDocument,
} from '../reducers/originalDocumentSlice';

const useOriginalDocument = (projectId, documentId) => {
  const dispatch = useDispatch();
  const originalDocumentBase64 = useSelector(selectOriginalDocument);
  const [isOriginalBase64Loading, setIsOriginalBase64Loading] = useState(false);

  const initSlice = async () => {
    setIsOriginalBase64Loading(true);
    await dispatch(fetchOriginalDocument({ projectId, documentId })).unwrap();
    setIsOriginalBase64Loading(false);
  };

  useEffect(() => {
    if (!projectId || !documentId) return;
    initSlice();
    return () => {
      // Clears original document state when component unmounts
      dispatch(clearOriginalDocument());
    };
  }, [documentId]);

  return {
    originalDocumentBase64,
    isOriginalBase64Loading,
    initOriginalDocument: initSlice,
  };
};

export default useOriginalDocument;
