import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import { ScrollMode, SpecialZoomLevel } from '@react-pdf-viewer/core';
import React from 'react';
import { generatePluginInstances } from '../../../../../services/documentService';
import IconButton from '../../../../atoms/tailwind/IconButton';
import PdfPreview from '../../../pdfPreview/PdfPreview';

/**
 * Renders the pdf preview with zoom controls
 *
 * @param {string} documentBase64 The pdf document to preview in base64 format
 * @param {boolean} isDocumentLoading Whether the document is still loading
 */
const PdfPreviewWithZoomControls = ({ documentBase64, isDocumentLoading }) => {
  const pluginInstances = generatePluginInstances();
  const { CurrentScale, ZoomIn, ZoomOut } = pluginInstances.zoomInstance;

  /**
   * Renders the zoom controls for the pdf preview
   */
  const renderZoomControls = () => {
    return (
      <div className="my-2 flex items-center justify-center gap-3 text-blue-2">
        <ZoomIn>
          {(props) => (
            <IconButton
              icon={<PlusIcon />}
              onClick={props.onClick}
              useReactIcons
              iconSize="small"
              color="white"
            />
          )}
        </ZoomIn>
        <CurrentScale>{(props) => `${Math.round(props.scale * 100)}%`}</CurrentScale>
        <ZoomOut>
          {(props) => (
            <IconButton
              icon={<MinusIcon />}
              onClick={props.onClick}
              iconSize="small"
              color="white"
              useReactIcons
            />
          )}
        </ZoomOut>
      </div>
    );
  };

  /**
   * Renders the pdf preview or a spinner icon if the pdf is still loading
   */
  const renderPdfPreview = () => {
    if (!isDocumentLoading && documentBase64) {
      return (
        <PdfPreview
          selectedParagraphIndex={0}
          base64Pdf={documentBase64}
          parentSize={{ height: 475, width: 450 }}
          scrollMode={ScrollMode.Vertical}
          scrollModeInstance="Vertical"
          defaultScale={SpecialZoomLevel.PageWidth}
          zoomInstance={pluginInstances.zoomInstance}
          pageNavigationInstance={pluginInstances.pageNavigationInstance}
        />
      );
    }
    return (
      // Height is set to 475px to match the height of the pdf preview
      <div className="flex h-[475px] min-h-[475px] min-w-full items-center justify-center">
        <div className="loader-container-small">
          <div className="loader" />
        </div>
      </div>
    );
  };

  return (
    <>
      {renderZoomControls()}
      {renderPdfPreview()}
    </>
  );
};

export default PdfPreviewWithZoomControls;
