import { useState } from 'react';
import annotationViewTypes from '../types/annotationViewTypes';
import documentTypes from '../types/documentTypes';
import sidebarContentAreaTypes from '../types/sidebarContentAreaTypes';

const getInitialState = (documentType) => {
  switch (documentType) {
    case documentTypes.IMAGE:
      return {
        activeSidebarContentArea: sidebarContentAreaTypes.imageAnonymization,
        activeAnnotationView: annotationViewTypes.imageAnnotation,
      };
    case documentTypes.PDF:
    case documentTypes.DOCX:
    case documentTypes.DOC:
      return {
        activeSidebarContentArea: sidebarContentAreaTypes.textLabelSelection,
        activeAnnotationView: annotationViewTypes.textAnnotation,
      };
    default:
      return {
        activeSidebarContentArea: null,
        activeAnnotationView: null,
      };
  }
};

const useSidebar = (documentType) => {
  const [activeSidebarContentArea, setActiveSidebarContentArea] = useState(
    getInitialState(documentType).activeSidebarContentArea,
  );
  const [activeAnnotationView, setActiveAnnotationView] = useState(
    getInitialState(documentType).activeAnnotationView,
  );

  const changeActiveSidebarView = (newAnnotationView, viewName) => {
    if (newAnnotationView !== activeAnnotationView) {
      setActiveAnnotationView(newAnnotationView);
    }
    setActiveSidebarContentArea(viewName);
  };

  const clearSidebarView = () => {
    setActiveSidebarContentArea(getInitialState(documentType).activeSidebarContentArea);
    setActiveAnnotationView(getInitialState(documentType).activeAnnotationView);
  };

  return {
    activeSidebarContentArea,
    activeAnnotationView,
    changeActiveSidebarView,
    clearSidebarView,
  };
};

export default useSidebar;
