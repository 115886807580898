import { Box, Flex } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VscChromeClose } from 'react-icons/vsc';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getDocumentInfo } from '../api/document';
import AppToaster from '../components/molecules/AppToaster';
import EditorAlert from '../components/molecules/EditorAlert';
import ImageAnnotationMain from '../components/organisms/imageAnnotation/ImageAnnotationMain';
import SidebarMain from '../components/organisms/sidebar/SidebarMain';
import TextAnnotationMain from '../components/organisms/textAnnotation/TextAnnotationMain';
import useAnonymizedDocument from '../hooks/useAnonymizedDocument';
import useDocumentData from '../hooks/useDocumentData';
import useDocumentImages from '../hooks/useDocumentImages';
import useImageAnnotation from '../hooks/useImageAnnotation';
import useImageLabels from '../hooks/useImageLabels';
import useLayout from '../hooks/useLayout';
import useMainMenu from '../hooks/useMainMenu';
import useOriginalDocument from '../hooks/useOriginalDocument';
import usePolling from '../hooks/usePolling';
import useProjectEvents from '../hooks/useProjectEvents';
import useSidebar from '../hooks/useSidebar';
import useTextData from '../hooks/useTextData';
import useTextLabels from '../hooks/useTextLabels';
import useUiHook from '../hooks/useUiHook';
import useVersionControl from '../hooks/useVersionControl';
import { updateVersion } from '../reducers/documentDataSlice';
import { generatePluginInstances } from '../services/documentService';
import annotationViewTypes from '../types/annotationViewTypes';

const Editor = () => {
  const dispatch = useDispatch();
  const organizationId = parseInt(useParams().organizationId, 10);
  const projectId = parseInt(useParams().projectId, 10);
  const documentId = parseInt(useParams().documentId, 10);
  const { contentHeight } = useLayout();
  const { t } = useTranslation();

  const {
    isSaving,
    isFinalizing,
    isCompiling,
    processingStatus,
    documentType,
    documentName,
    documentLastModified,
    documentLastFileModified,
    saveDocument,
    finalizeDocument,
    setIsCompiling,
  } = useDocumentData(projectId, documentId);
  const {
    textLabels,
    selectedTextLabelName,
    textLabelHiddenColor,
    hasTextLabelExtendedReplacement,
    selectTextLabelIfValid,
  } = useTextLabels(projectId);

  const { imageLabels, setLabelIsHidden: setImageLabelIsHidden } = useImageLabels(projectId);

  const {
    annotations,
    paragraphs,
    undoActionName,
    redoActionName,
    predictedSentenceStarts,
    sentenceStartIdsToRemove,
    createRenderableTextSegments,
    annotateAllUnmarked,
    findAndRemoveAnnotation,
    changeTextLabelNameOfAllAnnotationsWithSameText,
    setCrIdAndPseudonymOfAnnotation,
    removeAllAnnotationsWithSameTextLabelAndText,
    removeAllAnnotationsWithSameText,
    addAnnotation,
    changeTextLabelNameOfAnnotation,
    changeCrIdOfAnnotation,
    changeCrIdOfAllAnnotationsWithSameTextLabelAndText,
    removeAllAnnotationsWithTextLabel,
    undo,
    redo,
  } = useTextData(documentId);
  const {
    selectedParagraphIndex,
    isSidebarOpen,
    isDisplayingPseudonyms,
    setSelectedParagraphIndex,
    toggleSelectedAnnotation,
    selectAnnotation,
    deselectAnnotation,
    isAnnotationSelected,
    setIsSidebarOpen,
    setIsDisplayingPseudonyms,
  } = useUiHook();
  const {
    isDownloading,
    isFinalizingPopOverOpen,
    isDownloadPopOverOpen,
    setIsDownloadPopOverOpen,
    onCloseDocument,
    isPreviewLoading,
    setIsDownloading,
    setIsFinalizingPopOverOpen,
    setIsPreviewLoading,
  } = useMainMenu(organizationId, projectId);
  const {
    images,
    activeImageId,
    isLoading: isImagesLoading,
    undoActionName: imageAnnotationUndoActionName,
    redoActionName: imageAnnotationRedoActionName,
    sortImagesByPageId,
    setActiveImageId,
    getImageById,
    createRenderableBboxs,
    addBbox,
    deleteBboxInImage,
    updateBbox,
    changeLabelOfBbox,
    createRenderableDetections,
    getNumberOfBboxesOfLabel,
    redo: imageAnnotationRedo,
    undo: imageAnnotationUndo,
  } = useDocumentImages(documentId);
  const {
    isCreatingBbox,
    isEditingBbox,
    isMovingImageButtonMode,
    activeLabel,
    activeBbox,
    centeringScaleFactor,
    scaleFactor,
    translationVector,
    stageWidth,
    stageHeight,
    drawnKeyPoints,
    transformedKeyPoints,
    flattenedPoints,
    isPolyComplete,
    zoomAtStagePos,
    initAnnotatorStage,
    toStageFrame,
    toImageFrame,
    setStageWidth,
    setStageHeight,
    handlePointDragMove,
    handleMouseMove,
    setActiveBbox,
    setActiveLabel,
    cancelDrawing,
    beginEditingBbox,
    cancelEditingBbox,
    clearActiveBbox,
    setIsCreatingBbox,
    setIsMovingImageButtonMode,
    setBboxActive,
    centerView,
    clearBboxData,
    handleZoomIn,
    isLabelActive,
    isBboxActive,
    handleZoomOut,
    pointDragBoundFunc,
    setPressedMouseButton,
    handleZoomIntoMousePointer,
    computeDraggedKeyPoints,
    addKeyPoint,
    handleCreateBbox,
  } = useImageAnnotation(activeImageId, images);

  const { originalDocumentBase64, isOriginalBase64Loading, initOriginalDocument } =
    useOriginalDocument(projectId, documentId);
  const {
    anonymizedDocumentBase64,
    isAnonymizedBase64Loading,
    initAnonymizedDocument,
    downloadAnonymizedDocument,
  } = useAnonymizedDocument(projectId, documentId);
  const pluginInstances = generatePluginInstances();

  const {
    activeSidebarContentArea,
    activeAnnotationView,
    changeActiveSidebarView,
    clearSidebarView,
  } = useSidebar(documentType);

  const didUserStartCompilationRef = React.useRef(false);

  /**
   * Sets the didUserStartCompilationRef to the given value
   * @param {boolean} value
   */
  const setDidUserStartCompilationRef = (value) => {
    didUserStartCompilationRef.current = value;
  };

  // State for indicating to the user whether another user is currently editing the document
  const [isDocumentLocked, setIsDocumentLocked] = useState(false);
  // State for indicating to the user whether the compilation of the document failed
  const [didCompilationFail, setDidCompilationFail] = useState(false);

  /**
   * Deletes the active bounding box in the store
   */
  const onDeleteBbox = () => {
    deleteBboxInImage(activeBbox.id.labelIdx, activeBbox.id.bboxIdx);
    setActiveBbox(null);
    cancelEditingBbox();
  };

  const { isDocumentOutdated, initHashes } = useVersionControl(annotations, images);

  /**
   * This ref is used to store the last file modified date of the document.
   * It is updated when the document is saved and is then used to check when the document was modified again (i.e. compilation completed).
   */
  const documentLastFileModifiedRef = React.useRef(documentLastFileModified);

  /**
   * Initializes the original and anonymized document
   */
  const initDocument = () => {
    initOriginalDocument();
    initAnonymizedDocument();
  };

  /**
   * Polls the server to check if the document has been compiled successfully.
   * If the document has been compiled successfully, the document is reloaded and compilation states are reset.
   * @returns boolean indicating whether the compilation is complete and the polling should stop
   */
  const pollForCompilationCompletion = async () => {
    const result = await getDocumentInfo(projectId, documentId);
    // If the last file modified date of the document has changed, the document has been compiled successfully.
    if (result.data.lastFileModified !== documentLastFileModifiedRef.current) {
      dispatch(updateVersion(result.data.version));
      initDocument();
      AppToaster({
        description: t('main.toastMessages.documentCompilationSuccess'),
        status: 'success',
      });
      setIsCompiling(false);
      setDidCompilationFail(false);
      return true; // Returns true to indicate that the polling should stop
    }
    return false; // Returns false to indicate that the polling should continue
  };

  const { startPolling } = usePolling(pollForCompilationCompletion, 3000);

  const onSaveDocument = () => {
    // When the user saves the document, we need to update the hashes to track changes
    initHashes();
    saveDocument(
      paragraphs,
      annotations,
      predictedSentenceStarts,
      sentenceStartIdsToRemove,
      images,
      setDidUserStartCompilationRef,
    );
    documentLastFileModifiedRef.current = documentLastFileModified;
    startPolling();
  };

  const onFinalizeDocument = async () => {
    await finalizeDocument(
      paragraphs,
      annotations,
      predictedSentenceStarts,
      sentenceStartIdsToRemove,
      images,
      setDidUserStartCompilationRef,
    );
    onCloseDocument();
  };

  // TODO: Rework SSE handling (maybe use polling instead)
  /**
   * On events state that specifies the actions to be taken
   * when an event from the server is received
   */
  const [onEvents] = React.useState({
    DOCUMENT_COMPILED: (data) => {
      if (data.documentId === documentId) {
        if (didUserStartCompilationRef.current) {
          initDocument();
          AppToaster({
            description: t('main.toastMessages.documentCompilationSuccess'),
            status: 'success',
          });
          setIsCompiling(false);
          setDidCompilationFail(false);
          setDidUserStartCompilationRef(false);
        } else {
          setIsDocumentLocked(true);
        }
      }
    },
    DOCUMENT_COMPILE_FAILED: (data) => {
      if (data.documentId === documentId && didUserStartCompilationRef.current) {
        AppToaster({
          description: t('main.toastMessages.documentCompilationFailed'),
          status: 'error',
        });
        setIsCompiling(false);
        setDidCompilationFail(true);
        setDidUserStartCompilationRef(false);
      }
    },
  });
  useProjectEvents(projectId, onEvents);

  /**
   * Checks whether the document is finalized
   * @returns {boolean} true if the document is finalized, false otherwise
   */
  const isDocumentFinalized = () => {
    if (typeof processingStatus === 'undefined' || processingStatus === null) {
      return false;
    }
    return processingStatus === 'FINALIZED';
  };

  useEffect(() => {
    clearActiveBbox();
  }, [activeImageId]);

  useEffect(() => {
    clearSidebarView();
  }, [documentId, documentType]);

  return !isDocumentFinalized() ? (
    <Flex flexDir="row" height="100%" width="100%">
      <SidebarMain
        activeSidebarContentArea={activeSidebarContentArea}
        activeAnnotationView={activeAnnotationView}
        changeActiveSidebarView={changeActiveSidebarView}
        documentType={documentType}
        annotations={annotations}
        textLabels={textLabels}
        images={images}
        activeImageId={activeImageId}
        sortImagesByPageId={sortImagesByPageId}
        setActiveImageId={setActiveImageId}
        selectedTextLabelName={selectedTextLabelName}
        textLabelHiddenColor={textLabelHiddenColor}
        hasTextLabelExtendedReplacement={hasTextLabelExtendedReplacement}
        selectTextLabelIfValid={selectTextLabelIfValid}
        removeAllAnnotationsWithTextLabel={removeAllAnnotationsWithTextLabel}
        selectedParagraphIndex={selectedParagraphIndex}
        changeCrIdOfAnnotation={changeCrIdOfAnnotation}
        isOpen={isSidebarOpen}
        setIsOpen={setIsSidebarOpen}
        isImagesLoading={isImagesLoading}
      />
      <Box height={contentHeight} width="100%">
        {(() => {
          switch (activeAnnotationView) {
            case annotationViewTypes.textAnnotation:
              return (
                <TextAnnotationMain
                  annotations={annotations}
                  paragraphs={paragraphs}
                  isDocumentLocked={isDocumentLocked}
                  createRenderableTextSegments={createRenderableTextSegments}
                  annotateAllUnmarked={annotateAllUnmarked}
                  findAndRemoveAnnotation={findAndRemoveAnnotation}
                  changeTextLabelNameOfAllAnnotationsWithSameText={
                    changeTextLabelNameOfAllAnnotationsWithSameText
                  }
                  setCrIdAndPseudonymOfAnnotation={setCrIdAndPseudonymOfAnnotation}
                  removeAllAnnotationsWithSameTextLabelAndText={
                    removeAllAnnotationsWithSameTextLabelAndText
                  }
                  removeAllAnnotationsWithSameText={removeAllAnnotationsWithSameText}
                  addAnnotation={addAnnotation}
                  changeTextLabelNameOfAnnotation={changeTextLabelNameOfAnnotation}
                  changeCrIdOfAnnotation={changeCrIdOfAnnotation}
                  changeCrIdOfAllAnnotationsWithSameTextLabelAndText={
                    changeCrIdOfAllAnnotationsWithSameTextLabelAndText
                  }
                  textLabels={textLabels}
                  selectedTextLabelName={selectedTextLabelName}
                  selectedParagraphIndex={selectedParagraphIndex}
                  toggleSelectedAnnotation={toggleSelectedAnnotation}
                  selectAnnotation={selectAnnotation}
                  deselectAnnotation={deselectAnnotation}
                  isAnnotationSelected={isAnnotationSelected}
                  isDownloading={isDownloading}
                  setIsDownloading={setIsDownloading}
                  isFinalizingPopOverOpen={isFinalizingPopOverOpen}
                  setIsFinalizingPopOverOpen={setIsFinalizingPopOverOpen}
                  isDownloadPopOverOpen={isDownloadPopOverOpen}
                  setIsDownloadPopOverOpen={setIsDownloadPopOverOpen}
                  onSaveDocument={onSaveDocument}
                  setIsCompiling={setIsCompiling}
                  didCompilationFail={didCompilationFail}
                  onCloseDocument={onCloseDocument}
                  isPreviewLoading={isPreviewLoading}
                  isDocumentOutdated={isDocumentOutdated}
                  setIsPreviewLoading={setIsPreviewLoading}
                  originalDocumentBase64={originalDocumentBase64}
                  isOriginalBase64Loading={isOriginalBase64Loading}
                  anonymizedDocumentBase64={anonymizedDocumentBase64}
                  isAnonymizedBase64Loading={isAnonymizedBase64Loading}
                  downloadAnonymizedDocument={downloadAnonymizedDocument}
                  pluginInstances={pluginInstances}
                  setSelectedParagraphIndex={setSelectedParagraphIndex}
                  documentId={documentId}
                  isDisplayingPseudonyms={isDisplayingPseudonyms}
                  setIsDisplayingPseudonyms={setIsDisplayingPseudonyms}
                  undo={undo}
                  redo={redo}
                  undoActionName={undoActionName}
                  redoActionName={redoActionName}
                  isSaving={isSaving}
                  isCompiling={isCompiling}
                  saveDocument={onSaveDocument}
                  finalizeDocument={onFinalizeDocument}
                  isFinalizing={isFinalizing}
                  documentName={documentName}
                  documentLastModified={documentLastModified}
                />
              );
            case annotationViewTypes.imageAnnotation:
              return (
                <ImageAnnotationMain
                  activeImageId={activeImageId}
                  isLabelActive={isLabelActive}
                  isBboxActive={isBboxActive}
                  createRenderableDetections={createRenderableDetections}
                  getImageById={getImageById}
                  changeLabelOfBbox={changeLabelOfBbox}
                  setBboxActive={setBboxActive}
                  createRenderableBboxs={createRenderableBboxs}
                  isCreatingBbox={isCreatingBbox}
                  setIsCreatingBbox={setIsCreatingBbox}
                  isEditingBbox={isEditingBbox}
                  isMovingImageButtonMode={isMovingImageButtonMode}
                  activeLabel={activeLabel}
                  setActiveLabel={setActiveLabel}
                  activeBbox={activeBbox}
                  cancelDrawing={cancelDrawing}
                  beginEditingBbox={beginEditingBbox}
                  cancelEditingBbox={cancelEditingBbox}
                  clearActiveBbox={clearActiveBbox}
                  setActiveBbox={setActiveBbox}
                  setIsMovingImageButtonMode={setIsMovingImageButtonMode}
                  updateBbox={updateBbox}
                  addBbox={addBbox}
                  onDeleteBbox={onDeleteBbox}
                  centeringScaleFactor={centeringScaleFactor}
                  scaleFactor={scaleFactor}
                  translationVector={translationVector}
                  stageWidth={stageWidth}
                  stageHeight={stageHeight}
                  setStageWidth={setStageWidth}
                  setStageHeight={setStageHeight}
                  centerView={centerView}
                  zoomAtStagePos={zoomAtStagePos}
                  toStageFrame={toStageFrame}
                  toImageFrame={toImageFrame}
                  initAnnotatorStage={initAnnotatorStage}
                  handleZoomIn={handleZoomIn}
                  handleZoomOut={handleZoomOut}
                  drawnKeyPoints={drawnKeyPoints}
                  transformedKeyPoints={transformedKeyPoints}
                  flattenedPoints={flattenedPoints}
                  handlePointDragMove={handlePointDragMove}
                  handleMouseMove={handleMouseMove}
                  pointDragBoundFunc={pointDragBoundFunc}
                  setPressedMouseButton={setPressedMouseButton}
                  clearBboxData={clearBboxData}
                  handleZoomIntoMousePointer={handleZoomIntoMousePointer}
                  computeDraggedKeyPoints={computeDraggedKeyPoints}
                  addKeyPoint={addKeyPoint}
                  isPolyComplete={isPolyComplete}
                  getNumberOfBboxesOfLabel={getNumberOfBboxesOfLabel}
                  handleCreateBbox={handleCreateBbox}
                  onSaveDocument={onSaveDocument}
                  onCloseDocument={onCloseDocument}
                  isSaving={isSaving}
                  finalizeDocument={onFinalizeDocument}
                  isFinalizing={isFinalizing}
                  imageAnnotationUndoActionName={imageAnnotationUndoActionName}
                  imageAnnotationRedoActionName={imageAnnotationRedoActionName}
                  imageAnnotationUndo={imageAnnotationUndo}
                  imageAnnotationRedo={imageAnnotationRedo}
                  imageLabels={imageLabels}
                  setImageLabelIsHidden={setImageLabelIsHidden}
                />
              );
            default:
              return null;
          }
        })()}
      </Box>
    </Flex>
  ) : (
    <Box>
      <EditorAlert
        description={t('main.documentFinalizedAlert')}
        actionTooltip={t('main.documentFinalizedAlertTooltip')}
        onAction={onCloseDocument}
        actionIcon={<VscChromeClose />}
        type="warning"
      />
    </Box>
  );
};

export default Editor;
