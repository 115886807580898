import { Tooltip, theme } from '@chakra-ui/react';
import React from 'react';

const NavigatorStaticIcon = ({ icon, tooltip, navigatorWidth }) => {
  return (
    <Tooltip label={tooltip} placement="right" hasArrow>
      <div
        style={{
          width: navigatorWidth,
          height: '48px', // Set a fixed height similar to the chakra IconButton
          display: 'flex', // Centering the icon
          alignItems: 'center', // Vertically center the icon
          justifyContent: 'center', // Horizontally center the icon
          backgroundColor: theme.colors.gray[300],
          borderLeft: `4px solid ${theme.colors.gray[600]}`,
          color: theme.colors.gray[600],
          borderRadius: '0',
        }}
      >
        {icon}
      </div>
    </Tooltip>
  );
};

export default NavigatorStaticIcon;
