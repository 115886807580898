import { Center, Flex } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BiHighlight } from 'react-icons/bi';
import { BsImage } from 'react-icons/bs';
import { HiTable } from 'react-icons/hi';
import useLayout from '../../../hooks/useLayout';
import annotationViewTypes from '../../../types/annotationViewTypes';
import documentTypes from '../../../types/documentTypes';
import ContentArea from '../../molecules/ContentArea';
import NavigatorStaticIcon from '../../molecules/NavigatorStaticIcon';
import ImageSelection from './ImageSelection';
import Navigator from './Navigator';
import PseudonymizationView from './PseudonymizationView';
import TextLabelSelection from './TextLabelSelection';

/**
 * Sidebar component
 *
 * TODO: This implementation is messy and is only a temporary solution.
 * When the editor is refactored, this component should be refactored as well.
 * The introduction of image filetypes makes it even more necessary.
 *  - Separation of concerns:
 *      The image editor view and the document editor view should be separated into two different components.
 *      A main editor should switch between the two views based on the document type.
 *      The building blocks of the editor should still be shared between the two components.
 *      Each editor should have its own sidebar component, the static sidebar for images that is always collapsed and non clickable
 *      only has a similar look but is very different to the navigator style sidebar for documents (pdf, word, etc.).
 *  - More concice rendering logic:
 *      Its not directly clear what view and sidebar content is displayed when a navigator button is clicked in the sidebar.
 *      Currently this logic is spread out over this sidebar component and the editor view.
 *      There should be a single place where this logic is defined.
 */
const SidebarMain = ({
  activeSidebarContentArea,
  activeAnnotationView,
  changeActiveSidebarView,
  documentType,
  annotations,
  images,
  activeImageId,
  setActiveImageId,
  sortImagesByPageId,
  textLabels,
  selectedTextLabelName,
  selectTextLabelIfValid,
  hasTextLabelExtendedReplacement,
  textLabelHiddenColor,
  removeAllAnnotationsWithTextLabel,
  selectedParagraphIndex,
  changeCrIdOfAnnotation,
  isOpen,
  setIsOpen,
  isImagesLoading,
}) => {
  const { t } = useTranslation();
  const { navigatorWidth } = useLayout();

  const viewsDocument = {
    textLabelSelection: {
      icon: <BiHighlight size={30} />,
      component: (
        <TextLabelSelection
          selectedTextLabelName={selectedTextLabelName}
          textLabels={textLabels}
          textLabelHiddenColor={textLabelHiddenColor}
          hasTextLabelExtendedReplacement={hasTextLabelExtendedReplacement}
          selectTextLabelIfValid={selectTextLabelIfValid}
          removeAllAnnotationsWithTextLabel={removeAllAnnotationsWithTextLabel}
        />
      ),
      activeAnnotationView: annotationViewTypes.textAnnotation,
      isDisabled: annotations === null || annotations === undefined,
      tooltip: t('sidebar.views.textLabelSelection.tooltip'),
    },
    pseudonymization: {
      icon: <HiTable size={30} />,
      component: (
        <PseudonymizationView
          textLabels={textLabels}
          annotations={annotations}
          selectedParagraphIndex={selectedParagraphIndex}
          changeCrIdOfAnnotation={(annotation, newCrId) =>
            changeCrIdOfAnnotation(annotation, newCrId, textLabels)
          }
        />
      ),
      activeAnnotationView: annotationViewTypes.textAnnotation,
      isDisabled:
        annotations === null ||
        annotations === undefined ||
        textLabels === null ||
        textLabels === undefined ||
        Object.keys(textLabels).length === 0,
      tooltip: t('sidebar.views.pseudonymization.tooltip'),
    },
    imageAnonymization: {
      icon: <BsImage size={25} />,
      component: (
        <ImageSelection
          images={images}
          activeImageId={activeImageId}
          setActiveImageId={setActiveImageId}
          sortImagesByPageId={sortImagesByPageId}
        />
      ),
      activeAnnotationView: annotationViewTypes.imageAnnotation,
      isDisabled: !images || images.length === 0 || isImagesLoading,
      // TODO: !imageAnonymizationSettings.isEnabled,
      tooltip: (() => {
        if (images && images.length === 0) {
          return t('sidebar.views.imageAnonymization.tooltipWithoutImgs');
        }
        // if (!imageAnonymizationSettings.isEnabled) {
        //   return t('sidebar.views.imageAnonymization.tooltipSettingsDisabled');
        // }
        return t('sidebar.views.imageAnonymization.tooltip');
      })(),
    },
  };

  const viewsImage = {
    imageAnonymization: {
      icon: <BsImage size={25} />,
      tooltip: t('sidebar.views.imageAnonymization.tooltip'),
    },
  };

  const views = documentType === documentTypes.IMAGE ? viewsImage : viewsDocument;
  const isStaticSidebarSingleIcon = documentType === documentTypes.IMAGE;

  const navigatorWithContentArea = () => (
    <>
      <div width="60px" height="100%">
        <Navigator
          views={views}
          isSidebarOpen={isOpen}
          setIsSidebarOpen={setIsOpen}
          activeSidebarContentArea={activeSidebarContentArea}
          activeAnnotationView={activeAnnotationView}
          changeActiveSidebarView={changeActiveSidebarView}
        />
      </div>
      <ContentArea isOpen={isOpen}>
        {activeSidebarContentArea !== null && views[activeSidebarContentArea].component}
      </ContentArea>
    </>
  );

  const staticSidebarSingleIcon = () => (
    <Flex flexDir="column" bg="gray.400" p={0} width={navigatorWidth} height="100%">
      <Center key="ViewNavigatorButton-1">
        <NavigatorStaticIcon
          icon={Object.values(views)[0].icon}
          tooltip={Object.values(views)[0].tooltip}
          navigatorWidth={navigatorWidth}
        />
      </Center>
    </Flex>
  );

  return (
    <Flex flexDir="row" height="100%">
      {isStaticSidebarSingleIcon ? staticSidebarSingleIcon() : navigatorWithContentArea()}
    </Flex>
  );
};

export default SidebarMain;
