import React, { useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { transformToCSVData } from '../utils/csvUtils';

import useDocumentSummary from '../hooks/useDocumentSummary';
import useDocuments from '../hooks/useDocuments';
import useDocumentsTagsWithValues from '../hooks/useDocumentsTagsWithValues';
import useScores from '../hooks/useScores';
import useUiHook from '../hooks/useUiHook';

import AddIcon from '../components/atoms/icons/AddIcon';
import DeleteIcon from '../components/atoms/icons/DeleteIcon';
import DownloadIcon from '../components/atoms/icons/DownloadIcon';
import ExportIcon from '../components/atoms/icons/ExportIcon';
import MetricsIcon from '../components/atoms/icons/MetricsIcon';
import SettingsIcon from '../components/atoms/icons/SettingsIcon';
import SpinnerIcon from '../components/atoms/icons/SpinnerIcon';
import Dropzone from '../components/atoms/tailwind/Dropzone';
import IconButton from '../components/atoms/tailwind/IconButton';
import Tooltip from '../components/atoms/tailwind/Tooltip';
import UploadButton from '../components/atoms/tailwind/UploadButton';
import AppToaster from '../components/molecules/AppToaster';
import BreadCrumbs from '../components/molecules/tailwind/BreadCrumbs';
import CustomBasicContainerForContent from '../components/molecules/tailwind/CustomBasicContainerForContent';
import CustomButtonGroup from '../components/molecules/tailwind/CustomButtonGroup';
import Tag from '../components/molecules/tailwind/Tag';
import ViewLayout from '../components/molecules/tailwind/ViewLayout';
import ScoresDialog from '../components/organisms/scores/ScoresDialog';
import DocumentTable from '../components/organisms/tailwind/documents/DocumentTable';
import PreviewDialog from '../components/organisms/tailwind/documents/preview/PreviewDialog';
import useOrganizations from '../hooks/useOrganizations';
import useProject from '../hooks/useProject';
import useProjectEvents from '../hooks/useProjectEvents';
import useSystemFeatures from '../hooks/useSystemFeatures';
import useSystemLoadStatus from '../hooks/useSystemLoadStatus';
import { selectUserData } from '../reducers/userSlice';
import { checkProjectRole } from '../services/authService';
import { acceptedExtensions } from '../services/documentService';
import { truncateText } from '../utils/uiUtils';

const DocumentOverview = () => {
  const { t } = useTranslation();
  const { projectId, organizationId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { getOrgById } = useOrganizations(organizationId);
  const { projectData } = useProject(organizationId, projectId);
  const user = useSelector(selectUserData);

  const urlNames = {
    organizations: getOrgById(organizationId)?.name || '',
    projects: projectData?.name || '',
  };

  const { computeScores, scores, isScoresLoading } = useScores(projectId);

  const {
    documents,
    currentPage,
    documentsOffset,
    totalElements,
    isFirstPage,
    isLoading: isLoadingDocuments,
    searchValue,
    sortName,
    setSortName,
    sortLastModified,
    setSortLastModified,
    sortStatus,
    setSortStatus,
    sortDocumentType,
    setSortDocumentType,
    setSearchValue,
    setFromDateValue,
    setToDateValue,
    documentStatusValue,
    setDocumentStatusValue,
    changePage,
    filterDocuments,
    onUploadFiles,
    onDownloadDocuments,
    isDocumentPreprocessed,
    onDeleteDocuments,
    onUnfinalizeDocument,
    fetchDocumentsPaged,
    isDocumentFinalized,
    onResetDocument,
    onArchiveDocument,
    onUnarchiveDocument,
    onDownloadDocument,
    updateDocumentProcessingStatus,
    setDocumentTagValue,
    documentTypeValue,
    setDocumentTypeValue,
    sortDocumentTagValue,
    setSortDocumentTagValue,
    allDocuments,
    allDocumentsLoading,
  } = useDocuments(projectId);

  const { documentTagsWithValues, setDocumentTagsWithValues, placeholderDocumentTag } =
    useDocumentsTagsWithValues(projectId);

  const {
    isUploading,
    setIsUploading,
    setIsDeleting,
    setIsDownloading,
    selectedDocumentIds,
    clearSelectedDocumentIds,
    addToSelectedDocumentIds,
    removeFromSelectedDocumentIds,
    isDocumentSelected,
    isDocumentArchived,
    isSelectionEmpty,
    addAllToSelectedDocumentIds,
    isMetricsOpen,
    setIsMetricsOpen,
  } = useUiHook();

  const {
    onDocumentSummary,
    documentSummary,
    isSummaryLoading,
    isDocumentSummaryOpen,
    setIsDocumentSummaryOpen,
  } = useDocumentSummary();
  const { loadStatus } = useSystemLoadStatus(isLoadingDocuments);

  // Available system features (used for conditional rendering)
  const { availableFeatures } = useSystemFeatures();

  // State for PDF Preview
  const [documentIdForPreview, setDocumentIdForPreview] = React.useState(null);

  /**
   * On events state that specifies the actions to be taken
   * when an event from the server is received
   */
  const [onEvents, setOnEvents] = React.useState({
    DOCUMENT_UPDATED: () => {
      fetchDocumentsPaged({
        page: currentPage,
      });
    },
    DOCUMENT_COMPLETED: () => {
      fetchDocumentsPaged({
        page: currentPage,
      });
    },
    PROCESS_DOCUMENT_EXTRACTION_IMAGES_PROCESSING: (data) => {
      updateDocumentProcessingStatus(data.documentId, 'PROCESSING');
    },
    PROCESS_DOCUMENT_EXTRACTION_TEXT_PROCESSING: (data) => {
      updateDocumentProcessingStatus(data.documentId, 'PROCESSING');
    },
    PROCESS_DOCUMENT_DETECTION_TEXT_PROCESSING: (data) => {
      updateDocumentProcessingStatus(data.documentId, 'PROCESSING');
    },
    PROCESS_DOCUMENT_DETECTION_IMAGES_PROCESSING: (data) => {
      updateDocumentProcessingStatus(data.documentId, 'PROCESSING');
    },
    PROCESS_DOCUMENT_ANONYMIZATION_TEXT_PROCESSING: (data) => {
      updateDocumentProcessingStatus(data.documentId, 'PROCESSING');
    },
    PROCESS_DOCUMENT_ANONYMIZATION_IMAGES_PROCESSING: (data) => {
      updateDocumentProcessingStatus(data.documentId, 'PROCESSING');
    },
    PROCESS_DOCUMENT_ANONYMIZATION_DOCUMENT_PROCESSING: (data) => {
      updateDocumentProcessingStatus(data.documentId, 'PROCESSING');
    },
  });
  /*
   * Set the onEvents state every time the documents state changes
   * to ensure that the latest documents are used
   */
  useEffect(() => {
    setOnEvents({
      DOCUMENT_UPDATED: () => {
        fetchDocumentsPaged({
          page: currentPage,
        });
      },
      DOCUMENT_COMPLETED: () => {
        fetchDocumentsPaged({
          page: currentPage,
        });
      },
      PROCESS_DOCUMENT_EXTRACTION_IMAGES_PROCESSING: (data) => {
        updateDocumentProcessingStatus(data.documentId, 'PROCESSING');
      },
      PROCESS_DOCUMENT_EXTRACTION_TEXT_PROCESSING: (data) => {
        updateDocumentProcessingStatus(data.documentId, 'PROCESSING');
      },
      PROCESS_DOCUMENT_DETECTION_TEXT_PROCESSING: (data) => {
        updateDocumentProcessingStatus(data.documentId, 'PROCESSING');
      },
      PROCESS_DOCUMENT_DETECTION_IMAGES_PROCESSING: (data) => {
        updateDocumentProcessingStatus(data.documentId, 'PROCESSING');
      },
      PROCESS_DOCUMENT_ANONYMIZATION_TEXT_PROCESSING: (data) => {
        updateDocumentProcessingStatus(data.documentId, 'PROCESSING');
      },
      PROCESS_DOCUMENT_ANONYMIZATION_IMAGES_PROCESSING: (data) => {
        updateDocumentProcessingStatus(data.documentId, 'PROCESSING');
      },
      PROCESS_DOCUMENT_ANONYMIZATION_DOCUMENT_PROCESSING: (data) => {
        updateDocumentProcessingStatus(data.documentId, 'PROCESSING');
      },
    });
  }, [documents]);
  useProjectEvents(projectId, onEvents);

  const isSelectionIndeterminate = () => {
    return selectedDocumentIds.length > 0 && selectedDocumentIds.length < documents.length;
  };

  const areAllDocumentsSelected = () => {
    return selectedDocumentIds.length > 0 && documents.length === selectedDocumentIds.length;
  };

  const areSelectedDocumentsPreprocessed = () => {
    return documents
      .filter((document) => selectedDocumentIds.includes(document.id))
      .every((document) => isDocumentPreprocessed(document));
  };

  const areSelectedDocumentsFinalized = () => {
    return documents
      .filter((document) => selectedDocumentIds.includes(document.id))
      .every((document) => isDocumentFinalized(document));
  };

  const onUpload = async (files) => {
    setIsUploading(true);

    const { toastId, update, close } = AppToaster({
      description: t('documentSelection.uploadLoading'),
      status: 'loading',
      duration: null, // do not close automatically
    });

    try {
      await onUploadFiles(files, currentPage);

      update(t('documentSelection.uploadSuccess'), 'success', 'top-right', 3000);
    } catch (error) {
      close();
      console.error(error);
    }
    setIsUploading(false);
  };

  const onDelete = async (documentIds) => {
    if (!checkProjectRole(user, projectId, 'ADMIN')) {
      return;
    }
    setIsDeleting(true);
    try {
      await onDeleteDocuments(documentIds);
    } catch (error) {
      console.error(error);
    }
    setIsDeleting(false);
  };

  const onReset = async (documentId) => {
    setIsUploading(true);
    try {
      await onResetDocument(documentId);
    } catch (error) {
      console.error(error);
    }
    setIsUploading(false);
  };

  const onArchiveStatusOfDocument = async (documentId, documentStatus) => {
    try {
      if (documentStatus === 'ARCHIVED') {
        await onUnarchiveDocument(documentId);
      } else {
        await onArchiveDocument(documentId);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onDownloadSelectedDocuments = async (documentIds) => {
    setIsDownloading(true);
    try {
      await onDownloadDocuments(documentIds);
    } catch (error) {
      console.error(error);
    }
    setIsDownloading(false);
  };

  /**
   * Retrieves the document data in array format to export as csv
   * @param {Array} allDocs - All documents which are exported if no document is selected
   *
   * @returns AN object with header and data attributes
   */
  const getDocumentsCSVExportData = (allDocs) => {
    let documentsToExport = [];
    // Check if single documents are selected
    if (selectedDocumentIds.length > 0) {
      documents.forEach((document) => {
        if (selectedDocumentIds.includes(document.id)) {
          documentsToExport.push(document);
        }
      });
    } else {
      // Export all documents if no document is manually selected
      documentsToExport = allDocs;
    }

    return transformToCSVData(documentsToExport);
  };

  /**
   * Renders the CSV link to export the documents
   */
  const renderCSVLink = () => {
    const { headers, data } = getDocumentsCSVExportData(allDocuments);
    if (allDocumentsLoading || isUploading) {
      return (
        <IconButton
          disabled
          icon={<SpinnerIcon isSpinning />}
          color="blue"
          buttonText={t('documentSelection.exportButton')}
        />
      );
    }
    return (
      <CSVLink headers={headers} data={data} filename="documents.csv" separator=";">
        <IconButton
          icon={<ExportIcon />}
          color="blue"
          buttonText={t('documentSelection.exportButton')}
        />
      </CSVLink>
    );
  };

  useEffect(() => {
    clearSelectedDocumentIds();
  }, [documents]);

  /**
   * Handles the closing of the document preview modal by setting the state
   * to false and removing the document id
   */
  const handleDocumentPreviewClose = () => {
    setDocumentIdForPreview(null);
  };

  const contentDocumentOverview = () => (
    <>
      <div className="space-y-4">
        <CustomButtonGroup searchbar={false}>
          <div className="flex max-h-0 flex-col">
            <Tooltip
              position="bottom"
              text={`Formats: ${acceptedExtensions.join(', ')}, Max: ${
                availableFeatures.maxUploadFileSizeInMB
              } MB`}
            >
              <UploadButton
                buttonText={t('documentSelection.headerButtons.upload')}
                icon={<AddIcon />}
                color="blue"
                buttonAction={(event) => {
                  onUpload(event.target.files);
                }}
                isLoading={isUploading}
                loadingButton={
                  <div className="mx-[0.2rem] w-8">
                    <SpinnerIcon isSpinning />
                  </div>
                }
              />
            </Tooltip>
          </div>
          <div className="flex w-[50%] justify-between">
            <div className="space-x-3">
              {projectData?.isStoringPII && (
                <IconButton
                  buttonText={t('documentSelection.headerButtons.analyze')}
                  icon={<MetricsIcon />}
                  color="blue"
                  disabled={
                    !areSelectedDocumentsFinalized() ||
                    isScoresLoading ||
                    selectedDocumentIds.length === 0
                  }
                  buttonAction={async () => {
                    await computeScores(selectedDocumentIds);
                    setIsMetricsOpen(true);
                  }}
                />
              )}
              <IconButton
                buttonText={t('documentSelection.headerButtons.download')}
                icon={<DownloadIcon />}
                color="blue"
                disabled={!areSelectedDocumentsPreprocessed() || selectedDocumentIds.length === 0}
                buttonAction={() => {
                  onDownloadSelectedDocuments(selectedDocumentIds);
                }}
              />
              {checkProjectRole(user, projectId, 'ADMIN') ? (
                <IconButton
                  buttonText={t('documentSelection.headerButtons.delete')}
                  icon={<DeleteIcon />}
                  color="blue"
                  disabled={selectedDocumentIds.length === 0}
                  buttonAction={() => {
                    onDelete(selectedDocumentIds);
                  }}
                />
              ) : null}
            </div>
            <div className="space-x-3">
              {renderCSVLink()}
              {user && checkProjectRole(user, projectId, 'ADMIN') ? (
                <IconButton
                  icon={<SettingsIcon />}
                  color="white"
                  buttonAction={() => {
                    navigate(`/organizations/${organizationId}/projects/${projectId}/settings`);
                  }}
                />
              ) : null}
            </div>
          </div>
        </CustomButtonGroup>
      </div>
      <div className="mx-auto w-full">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 ">
              <Dropzone
                bg="white"
                rounded={5}
                height="100%"
                width="100%"
                overflow="auto"
                acceptedExtensions={acceptedExtensions}
                onDropAccepted={onUpload}
              >
                <DocumentTable
                  projectDocuments={documents}
                  addToSelectedDocumentIds={addToSelectedDocumentIds}
                  removeFromSelectedDocumentIds={removeFromSelectedDocumentIds}
                  isDocumentSelected={isDocumentSelected}
                  isDocumentArchived={isDocumentArchived}
                  selectedDocumentIds={selectedDocumentIds}
                  onDelete={onDelete}
                  onReset={onReset}
                  onArchiveStatusOfDocument={onArchiveStatusOfDocument}
                  onDownload={onDownloadDocument}
                  isSelectionEmpty={isSelectionEmpty}
                  isSelectionIndeterminate={isSelectionIndeterminate}
                  areAllDocumentsSelected={areAllDocumentsSelected}
                  addAllToSelectedDocumentIds={addAllToSelectedDocumentIds}
                  removeAllFromSelectedDocumentIds={clearSelectedDocumentIds}
                  organizationId={organizationId}
                  projectId={projectId}
                  onUnfinalizeDocument={onUnfinalizeDocument}
                  isDocumentSummaryEnabled={availableFeatures?.pdfSummaryEnabled || false}
                  onDocumentSummary={onDocumentSummary}
                  isSummaryLoading={isSummaryLoading}
                  isDocumentSummaryOpen={isDocumentSummaryOpen}
                  setIsDocumentSummaryOpen={setIsDocumentSummaryOpen}
                  documentSummary={documentSummary}
                  totalElements={totalElements}
                  documentsOffset={documentsOffset}
                  currentPage={currentPage}
                  isFirstPage={isFirstPage}
                  isLoadingDocuments={isLoadingDocuments}
                  changePage={changePage}
                  filterDocuments={filterDocuments}
                  fetchDocumentsPaged={fetchDocumentsPaged}
                  documentTagsWithValues={documentTagsWithValues}
                  setDocumentTagsWithValues={setDocumentTagsWithValues}
                  setDocumentTagValue={setDocumentTagValue}
                  documentTypeValue={documentTypeValue}
                  setDocumentTypeValue={setDocumentTypeValue}
                  searchValue={searchValue}
                  documentStatusValue={documentStatusValue}
                  setSearchValue={setSearchValue}
                  setFromDateValue={setFromDateValue}
                  setToDateValue={setToDateValue}
                  setDocumentStatusValue={setDocumentStatusValue}
                  sortName={sortName}
                  setSortName={setSortName}
                  sortLastModified={sortLastModified}
                  setSortLastModified={setSortLastModified}
                  sortDocumentType={sortDocumentType}
                  setSortDocumentType={setSortDocumentType}
                  sortStatus={sortStatus}
                  setSortStatus={setSortStatus}
                  sortDocumentTagValue={sortDocumentTagValue}
                  setSortDocumentTagValue={setSortDocumentTagValue}
                  placeholderDocumentTag={placeholderDocumentTag}
                  deleteEnabled={checkProjectRole(user, projectId, 'ADMIN')}
                  showDocumentPreview={setDocumentIdForPreview}
                  isProjectStoringPII={projectData?.isStoringPII}
                />
              </Dropzone>
            </div>
            {projectData?.isStoringPII && (
              <ScoresDialog
                scores={scores}
                showDialog={isMetricsOpen}
                onClose={() => setIsMetricsOpen(false)}
              />
            )}
          </div>
        </div>
        <PreviewDialog
          open={documentIdForPreview !== null && documentIdForPreview !== undefined}
          documentType={documents.find((doc) => doc.id === documentIdForPreview)?.documentType}
          documentName={truncateText(
            documents.find((doc) => doc.id === documentIdForPreview)?.original || '',
            50,
          )}
          onClose={handleDocumentPreviewClose}
          projectId={projectId}
          documentId={documentIdForPreview}
        />
      </div>
    </>
  );

  /**
   * Renders the load status tag with the correct color and tooltip
   * @returns {JSX.Element} The load status tag with the correct color and tooltip
   */
  const renderLoadStatusTag = () => {
    switch (loadStatus.systemLoadState) {
      case 'MEDIUM':
        return (
          <Tooltip position="bottom" text={t('documentSelection.loadStatus.medium.tooltip')}>
            <Tag
              name={t('documentSelection.loadStatus.medium.text')}
              color="orange"
              hoverColor="orange"
              activeColor="orange"
              active
            />
          </Tooltip>
        );
      case 'HIGH':
        return (
          <Tooltip position="bottom" text={t('documentSelection.loadStatus.high.tooltip')}>
            <Tag
              name={t('documentSelection.loadStatus.high.text')}
              color="red"
              hoverColor="red"
              activeColor="red"
              active
            />
          </Tooltip>
        );
      default:
        return null;
    }
  };

  return (
    <CustomBasicContainerForContent>
      <ViewLayout
        breadCrumbs={<BreadCrumbs urlNames={urlNames} urlPath={location.pathname} />}
        title={t('documentSelection.heading')}
        content={contentDocumentOverview()}
        additionalInfo={renderLoadStatusTag()}
      />
    </CustomBasicContainerForContent>
  );
};

export default DocumentOverview;
