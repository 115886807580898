import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearAnonymizedDocument,
  fetchAnonymizedDocument,
  selectAnonymizedDocument,
} from '../reducers/anonymizedDocumentSlice';

const useAnonymizedDocument = (projectId, documentId) => {
  const dispatch = useDispatch();
  const anonymizedDocumentBase64 = useSelector(selectAnonymizedDocument);
  const [isAnonymizedBase64Loading, setIsAnonymizedBase64Loading] = useState(false);

  const initSlice = async () => {
    setIsAnonymizedBase64Loading(true);
    await dispatch(fetchAnonymizedDocument({ projectId, documentId })).unwrap();
    setIsAnonymizedBase64Loading(false);
  };

  useEffect(() => {
    if (!projectId || !documentId) return;
    initSlice();
    // Clears anonymized document state when component unmounts
    // eslint-disable-next-line consistent-return
    return () => {
      dispatch(clearAnonymizedDocument());
    };
  }, [documentId]);

  /**
   * Downloads the anonymized document
   */
  const downloadAnonymizedDocument = async () => {
    await downloadAnonymizedDocument(projectId, documentId);
  };

  return {
    anonymizedDocumentBase64,
    isAnonymizedBase64Loading,
    downloadAnonymizedDocument,
    initAnonymizedDocument: initSlice,
  };
};

export default useAnonymizedDocument;
