import { Grid, GridItem } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdRefresh } from 'react-icons/md';
import EditorAlert from '../../molecules/EditorAlert';
import PdfPreviewController from '../pdfPreview/PdfPreviewController';
import TextAnnotationController from './TextAnnotationController';
import TextAnnotationTopMenu, { menuHeight } from './TextAnnotationTopMenu';

const TextAnnotationMain = ({
  textLabels,
  selectedTextLabelName,
  isDocumentLocked,
  annotations,
  paragraphs,
  createRenderableTextSegments,
  annotateAllUnmarked,
  findAndRemoveAnnotation,
  changeTextLabelNameOfAllAnnotationsWithSameText,
  setCrIdAndPseudonymOfAnnotation,
  removeAllAnnotationsWithSameTextLabelAndText,
  removeAllAnnotationsWithSameText,
  addAnnotation,
  changeTextLabelNameOfAnnotation,
  changeCrIdOfAnnotation,
  changeCrIdOfAllAnnotationsWithSameTextLabelAndText,
  selectedParagraphIndex,
  toggleSelectedAnnotation,
  selectAnnotation,
  deselectAnnotation,
  isAnnotationSelected,
  isSaving,
  isCompiling,
  setIsCompiling,
  didCompilationFail,
  isDownloading,
  setIsDownloading,
  isFinalizingPopOverOpen,
  setIsFinalizingPopOverOpen,
  isDownloadPopOverOpen,
  setIsDownloadPopOverOpen,
  onCloseDocument,
  isPreviewLoading,
  isDocumentOutdated,
  setIsPreviewLoading,
  anonymizedDocumentBase64,
  isAnonymizedBase64Loading,
  downloadAnonymizedDocument,
  originalDocumentBase64,
  isOriginalBase64Loading,
  pluginInstances,
  setSelectedParagraphIndex,
  documentId,
  isDisplayingPseudonyms,
  setIsDisplayingPseudonyms,
  undo,
  redo,
  undoActionName,
  redoActionName,
  saveDocument,
  isFinalizing,
  finalizeDocument,
  documentName,
  documentLastModified,
}) => {
  const { t } = useTranslation();

  return document ? (
    <Grid
      width="100%"
      height="100%"
      templateRows={
        isDocumentLocked
          ? `50px calc(${menuHeight}) calc(100% - ${menuHeight} - 50px)`
          : `calc(${menuHeight}) calc(100% - ${menuHeight})`
      }
      templateColumns="100%"
    >
      <GridItem w="100%" h="100%">
        <TextAnnotationTopMenu
          selectedParagraphIndex={selectedParagraphIndex}
          setSelectedParagraphIndex={setSelectedParagraphIndex}
          paragraphs={paragraphs}
          zoomInstance={pluginInstances.zoomInstance}
          scrollModeInstance={pluginInstances.scrollModeInstance}
          pageNavigationInstance={pluginInstances.pageNavigationInstance}
          isSaving={isSaving}
          isDownloading={isDownloading}
          setIsDownloading={setIsDownloading}
          documentId={documentId}
          isFinalizingPopOverOpen={isFinalizingPopOverOpen}
          setIsFinalizingPopOverOpen={setIsFinalizingPopOverOpen}
          isDownloadPopOverOpen={isDownloadPopOverOpen}
          setIsDownloadPopOverOpen={setIsDownloadPopOverOpen}
          onCloseDocument={onCloseDocument}
          setIsPreviewLoading={setIsPreviewLoading}
          isPreviewLoading={isPreviewLoading}
          downloadAnonymizedDocument={() => downloadAnonymizedDocument()}
          onSaveDocument={saveDocument}
          setIsCompiling={setIsCompiling}
          isDisplayingPseudonyms={isDisplayingPseudonyms}
          setIsDisplayingPseudonyms={setIsDisplayingPseudonyms}
          isFinalizing={isFinalizing}
          finalizeDocument={finalizeDocument}
          undo={undo}
          redo={redo}
          undoActionName={undoActionName}
          redoActionName={redoActionName}
          documentName={documentName}
          documentLastModified={documentLastModified}
        />
      </GridItem>
      {isDocumentLocked ? (
        <GridItem w="100%" h="100%" pr="2" pl="2">
          <EditorAlert
            description={t('main.documentIsLockedMessage')}
            actionTooltip={t('main.reload')}
            onAction={() => window.location.reload()}
            actionIcon={<MdRefresh />}
            type="error"
          />
        </GridItem>
      ) : null}
      <GridItem w="100%" h="100%">
        <Grid
          templateColumns="repeat(2, 1fr)"
          templateRows="100%"
          bg="gray.200"
          gap="2"
          padding="2"
          width="100%"
          height="100%"
          fontSize="18px"
        >
          <GridItem w="100%" height="100%" colSpan={1}>
            <TextAnnotationController
              annotations={annotations}
              paragraphs={paragraphs}
              createRenderableTextSegments={createRenderableTextSegments}
              annotateAllUnmarked={annotateAllUnmarked}
              findAndRemoveAnnotation={findAndRemoveAnnotation}
              changeTextLabelNameOfAllAnnotationsWithSameText={
                changeTextLabelNameOfAllAnnotationsWithSameText
              }
              setCrIdAndPseudonymOfAnnotation={setCrIdAndPseudonymOfAnnotation}
              removeAllAnnotationsWithSameTextLabelAndText={
                removeAllAnnotationsWithSameTextLabelAndText
              }
              removeAllAnnotationsWithSameText={removeAllAnnotationsWithSameText}
              addAnnotation={addAnnotation}
              changeTextLabelNameOfAnnotation={changeTextLabelNameOfAnnotation}
              changeCrIdOfAnnotation={changeCrIdOfAnnotation}
              changeCrIdOfAllAnnotationsWithSameTextLabelAndText={
                changeCrIdOfAllAnnotationsWithSameTextLabelAndText
              }
              textLabels={textLabels}
              selectedTextLabelName={selectedTextLabelName}
              selectedParagraphIndex={selectedParagraphIndex}
              toggleSelectedAnnotation={toggleSelectedAnnotation}
              selectAnnotation={selectAnnotation}
              deselectAnnotation={deselectAnnotation}
              isAnnotationSelected={isAnnotationSelected}
              isDisplayingPseudonyms={isDisplayingPseudonyms}
            />
            {/* <ImageAnomyzationSelectionAreaControl /> */}
          </GridItem>
          <GridItem h="100%" w="100%" colSpan={1}>
            <PdfPreviewController
              isCompiling={isCompiling}
              didCompilationFail={didCompilationFail}
              anonymizedDocumentBase64={anonymizedDocumentBase64}
              isAnonymizedDocumentBase64Loading={isAnonymizedBase64Loading}
              originalDocumentBase64={originalDocumentBase64}
              isOriginalDocumentBase64Loading={isOriginalBase64Loading}
              isDocumentOutdated={isDocumentOutdated}
              zoomInstance={pluginInstances.zoomInstance}
              scrollModeInstance={pluginInstances.scrollModeInstance}
              pageNavigationInstance={pluginInstances.pageNavigationInstance}
              isDisplayingPseudonyms={isDisplayingPseudonyms}
              selectedParagraphIndex={selectedParagraphIndex}
            />
            {/* <ScoresDialog
            showDialog={showScoresDialog}
            onClose={() => setShowScoresDialog(false)}
            annotations={annotations}
            goldAnnotations={predictedAnnotations}
          /> */}
            {/* <ImageAnomyzationProcessingAreaControl /> */}
          </GridItem>
        </Grid>
      </GridItem>
    </Grid>
  ) : null;
};

export default TextAnnotationMain;
