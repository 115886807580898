import React from 'react';
import { MdOutlineDocumentScanner } from 'react-icons/md';

const PreviewIcon = () => {
  return (
    <MdOutlineDocumentScanner
      style={{
        width: '38',
        height: '34',
        viewBox: '0 0 38 34',
        padding: '7',
      }}
    />
  );
};

export default PreviewIcon;
