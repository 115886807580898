import { useMatomo } from '@datapunt/matomo-tracker-react';
import React, { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import doccapeLogo from '../assets/DocCape_Favicon.svg';
import ThumbsUp from '../assets/DocCape_Hand_thumbsup_yellow.png';
import Checkbox from '../components/atoms/tailwind/Checkbox';
import CustomCard from '../components/molecules/tailwind/CustomCard';
import CustomForm from '../components/molecules/tailwind/CustomForm';
import LinkText from '../components/molecules/tailwind/LinkText';
import ExternalViewLayout from '../components/organisms/tailwind/ExternalViewLayout';
import useRegistration from '../hooks/useRegistration';
import useSystemPublicFeatures from '../hooks/useSystemPublicFeatures';
import extractQueryParamsFromURL from '../utils/extractQueryParamsFromURL';

/**
 * Renders the registration view
 */
const RegisterUser = () => {
  const location = useLocation();
  const queryParams = extractQueryParamsFromURL(location.search);
  const { t } = useTranslation();
  const { trackPageView, trackEvent } = useMatomo();
  const hasTrackedRef = useRef(false); // Ref to ensure tracking happens only once
  const { availablePublicFeatures } = useSystemPublicFeatures();

  const {
    acceptedTerms,
    isValidFirstName,
    isValidLastName,
    isValidEmail,
    isValidRepeatPassword,
    isValidPassword,
    errorMsg,
    password,
    repeatPassword,
    firstName,
    lastName,
    email,
    isRegistrationSuccessful,
    setAcceptedTerms,
    setEmail,
    setPassword,
    setRepeatPassword,
    setFirstName,
    setLastName,
    registerUser,
  } = useRegistration(queryParams);

  const handlePassword = (pw) => {
    setPassword(pw);
  };

  const handleRepeatPassword = (pw) => {
    setRepeatPassword(pw);
  };

  const handleFirstName = (name) => {
    setFirstName(name);
  };

  const handleLastName = (name) => {
    setLastName(name);
  };

  const handleEmail = (mail) => {
    setEmail(mail);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    registerUser();
     if (availablePublicFeatures.demoEnabled) {
       // Track registration event in Matomo
       trackEvent({
         category: 'Button',
         action: 'Click',
         name: 'Register Button clicked',
       });
    }
  };

  const linkContents = {
    /* linkGTC: { to: 'https://doccape.de/agb', title: 'doccape.de/agb' }, */
    linkPN: { to: 'https://doccape.de/datenschutz', title: 'doccape.de/datenschutz' },
  };

  // useEffect for tracking the page view
    useEffect(() => {
      if (availablePublicFeatures.demoEnabled && !hasTrackedRef.current) {
        trackPageView({
          documentTitle: 'Registration Page',
          href: window.location.href,
        });
        hasTrackedRef.current = true; // Mark as tracked
      }
    }, [availablePublicFeatures, trackPageView]);

  /**
   * Input fields for the registration form
   */
  const registerInputFields = [
    {
      id: 'firstname',
      name: t('organizationMngmt.registrationUser.userData.firstName'),
      type: 'text',
      value: firstName,
      onChange: (event) => handleFirstName(event.target.value),
      required: true,
      valid: isValidFirstName,
      errorMsg,
    },
    {
      id: 'lastname',
      name: t('organizationMngmt.registrationUser.userData.lastName'),
      type: 'text',
      value: lastName,
      onChange: (event) => handleLastName(event.target.value),
      required: true,
      valid: isValidLastName,
      errorMsg,
    },
    {
      id: 'email',
      name: t('organizationMngmt.registrationUser.userData.email'),
      type: 'email',
      value: email,
      onChange: (event) => handleEmail(event.target.value),
      required: true,
      valid: isValidEmail,
      errorMsg,
    },
    {
      id: 'password',
      name: t('organizationMngmt.registrationUser.userData.password'),
      type: 'password',
      value: password,
      onChange: (event) => handlePassword(event.target.value),
      required: true,
      valid: isValidPassword,
      errorMsg,
    },
    {
      id: 'repeatPassword',
      name: t('organizationMngmt.registrationUser.userData.repeatPassword'),
      type: 'password',
      value: repeatPassword,
      onChange: (event) => handleRepeatPassword(event.target.value),
      required: true,
      valid: isValidRepeatPassword,
      errorMsg,
    },
  ];

  /**
   * Checkbox for accepting terms and privacy
   */
  const termsAndPrivacyCheckbox = (
    <div>
      <div className="flex items-center">
        <Checkbox
          id="termsAndPrivacy"
          onChange={() => {
            setAcceptedTerms(!acceptedTerms);
          }}
        />
        <p className="ml-2 text-sm text-grey-1">
          {/* Trans component allows to integrate react components into translations */}
          <Trans
            i18nKey="organizationMngmt.registrationUser.userData.infoText"
            components={{
              /* linkGTC: <LinkText to={linkContents.linkGTC.to} title={linkContents.linkGTC.title} />, */
              linkPN: <LinkText to={linkContents.linkPN.to} title={linkContents.linkPN.title} />,
            }}
          />
        </p>
        <div className="mt-1 text-red-3 lg:text-xs">{errorMsg}</div>
      </div>
    </div>
  );

  const registerForm = (
    <CustomForm inputFields={registerInputFields} additionalContent={termsAndPrivacyCheckbox} />
  );

  const logoImage = (
    <img className="h-10 w-10 rounded-full bg-white" src={doccapeLogo} alt="doccape logo" />
  );

  /**
   * Renders the content depending on the state of the registration process (successful or not)
   */
  const renderRegistraionContent = () => {
    if (isRegistrationSuccessful) {
      return (
        <CustomCard
          image={logoImage}
          title={t('organizationMngmt.registrationUserSuccess.heading')}
          subtitle={t('organizationMngmt.registrationUserSuccess.subHeading')}
          content={
            <>
              <div className="flex w-full items-center justify-center">
                <img className="w-20" src={ThumbsUp} alt="Thumbs Icon" />
              </div>
              <p className="mt-3 text-center text-sm text-grey-1">
                {t('organizationMngmt.registrationUserSuccess.infoText')}
              </p>
            </>
          }
        />
      );
    }

    return (
      <CustomCard
        image={logoImage}
        title={t('organizationMngmt.registrationUser.heading')}
        subtitle={t('organizationMngmt.registrationUser.subHeading')}
        buttonText={t('organizationMngmt.registrationUser.createAccountButton')}
        disabledButton={!acceptedTerms}
        buttonAction={onSubmit}
        content={registerForm}
      />
    );
  };

  return <ExternalViewLayout>{renderRegistraionContent()}</ExternalViewLayout>;
};

export default RegisterUser;
