import { React, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BsArchive,
  BsCardImage,
  BsCloudDownload,
  BsFiletypeDoc,
  BsFiletypeDocx,
  BsFiletypePdf,
  BsTrash3,
} from 'react-icons/bs';
import { MdOutlineDocumentScanner } from 'react-icons/md';
import { RxReset } from 'react-icons/rx';
import { useNavigate } from 'react-router-dom';
import { createHumanReadableRepresentationDate } from '../../../../services/utils';
import { classNames } from '../../../../utils/tailwindUtils';
import DownloadIcon from '../../../atoms/icons/DownloadIcon';

import IconButton from '../../../atoms/tailwind/IconButton';
import MenuButton from '../../../atoms/tailwind/MenuButton';
import CustomDialog from '../../../molecules/tailwind/CustomDialog';
import DocumentSummaryDialogContent from '../../../molecules/tailwind/DocumentSummaryDialogContent';

import { truncateText } from '../../../../utils/uiUtils';
import EditorIcon from '../../../atoms/icons/EditorIcon';
import LockIcon from '../../../atoms/icons/LockIcon';
import MoreIcon from '../../../atoms/icons/MoreIcon';
import PreviewIcon from '../../../atoms/icons/PreviewIcon';
import SummaryIcon from '../../../atoms/icons/SummaryIcon';
import SortableTableHeading from '../../../molecules/tailwind/SortableTableHeading';
import TablePaginationFooter from '../../../molecules/tailwind/TablePaginationFooter';
import LoadingPage from '../../LoadingPage';
import DocumentTableFilterHeader from './DocumentTableFilterHeader';

/**
 * Displays table with Users and the corresponding actions
 * @param {array} orgUsers array of objects which describe users {id, email, firstName, lastName, version, organizationRole, isEmailVeryfied}
 * @param {function} updateUserRole function which handles the update of the role of a corresponding user
 */
const DocumentTable = ({
  projectDocuments,
  addToSelectedDocumentIds,
  removeFromSelectedDocumentIds,
  isDocumentSelected,
  isDocumentArchived,
  selectedDocumentIds,
  isSelectionEmpty,
  isSelectionIndeterminate,
  areAllDocumentsSelected,
  addAllToSelectedDocumentIds,
  removeAllFromSelectedDocumentIds,
  organizationId,
  projectId,
  onDelete,
  onUnfinalizeDocument,
  onArchiveStatusOfDocument,
  onReset,
  onDownload,
  isDocumentSummaryEnabled,
  onDocumentSummary,
  isSummaryLoading,
  isDocumentSummaryOpen,
  setIsDocumentSummaryOpen,
  documentSummary,
  totalElements,
  documentsOffset,
  currentPage,
  isFirstPage,
  changePage,
  filterDocuments,
  fetchDocumentsPaged,
  isLoadingDocuments,
  searchValue,
  setSearchValue,
  setFromDateValue,
  setToDateValue,
  documentStatusValue,
  setDocumentStatusValue,
  documentTypeValue,
  setDocumentTypeValue,
  sortName,
  setSortName,
  sortLastModified,
  setSortLastModified,
  sortStatus,
  setSortStatus,
  sortDocumentType,
  setSortDocumentType,
  documentTagsWithValues,
  setDocumentTagsWithValues,
  setDocumentTagValue,
  sortDocumentTagValue,
  setSortDocumentTagValue,
  placeholderDocumentTag,
  deleteEnabled,
  showDocumentPreview,
  isProjectStoringPII,
}) => {
  const checkbox = useRef();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [documentIdToSummarize, setDocumentIdToSummarize] = useState(null);

  /**
   * fires every time a document is selected or diselected in the table to allow the checkbox in the header
   * of the table to know when it should be checked or not
   */
  useLayoutEffect(() => {
    // the intederminate state cannot be set via html attribute, thus we mus use a ref
    checkbox.current.indeterminate = isSelectionIndeterminate();
  }, [selectedDocumentIds]);

  /**
   * toggles all users inside the table
   */
  const toggleAll = () => {
    if (isSelectionEmpty()) {
      addAllToSelectedDocumentIds(projectDocuments);
    } else {
      removeAllFromSelectedDocumentIds();
    }
  };

  /**
   * Checks if a document is in the chosen document list and removes it or adds it everytime the checkbox
   * is checked or unchecked
   * @param {number} documentId corresponding document id to add/remove from checked document selection
   * @param {boolean} isChecked boolean which indicates if the document is selected or not
   */
  const onChangeDocumentSelection = (documentId, isChecked) => {
    if (isChecked) {
      addToSelectedDocumentIds([documentId]);
    } else {
      removeFromSelectedDocumentIds([documentId]);
    }
  };

  /**
   * Quick actions for the project card
   */
  const getQuickActions = (document) => {
    const quickActions = [
      ...(isProjectStoringPII
        ? [
            {
              id: 'resetDocument',
              name: t('documentSelection.reset'),
              icon: <RxReset />,
              onClick: () => {
                onReset(document.id);
              },
              disabled: document.processingStatus === 'ARCHIVED',
            },
          ]
        : []),
      {
        id: 'archiveStatusOfDocument',
        name:
          document.processingStatus !== 'ARCHIVED'
            ? t('documentSelection.archived')
            : t('documentSelection.unarchived'),
        icon: <BsArchive />,
        onClick: () => {
          onArchiveStatusOfDocument(document.id, document.processingStatus);
        },
        disabled:
          document.processingStatus !== 'ARCHIVED' &&
          document.processingStatus !== 'COMPLETED' &&
          document.processingStatus !== 'FINALIZED',
      },
      ...(isProjectStoringPII
        ? [
            {
              id: 'downloadDocument',
              name: t('documentSelection.download'),
              icon: <BsCloudDownload />,
              onClick: () => {
                onDownload(document.id);
              },
              disabled:
                document.processingStatus !== 'FINALIZED' &&
                document.processingStatus !== 'COMPLETED',
            },
            {
              id: 'openDocumentPreview',
              name: t('documentSelection.preview'),
              icon: <MdOutlineDocumentScanner />,
              disabled:
                document.processingStatus !== 'FINALIZED' &&
                document.processingStatus !== 'COMPLETED',
              onClick: () => {
                showDocumentPreview(document.id);
              },
            },
          ]
        : []),
      ...(deleteEnabled
        ? [
            {
              id: 'deleteDocument',
              icon: <BsTrash3 />,
              name: t('documentSelection.delete'),
              onClick: () => {
                onDelete(document.id);
              },
              disabled: document.processingStatus === 'ARCHIVED',
            },
          ]
        : []),
    ];
    return quickActions;
  };

  /**
   * Opens the summary of a given dokument
   * @param {number} id id of the document which should be summarized
   */
  const openSummary = async (projectID, documentID) => {
    await onDocumentSummary(projectID, documentID);
  };

  /**
   * Opens the editor of a given dokument
   * @param {number} id id of the document which should be opened in the editor
   */
  const openEditor = (id) => {
    navigate(`/organizations/${organizationId}/projects/${projectId}/document/${id}`);
  };

  /**
   * Unfinalizes a given document
   * @param {number} id - ID of the document which should be unfinalized
   */
  const unfinalizeDocument = async (id) => {
    await onUnfinalizeDocument(id);
  };

  /**
   * Set state when clicking on close button
   */
  const closeDialog = () => {
    setIsDocumentSummaryOpen(false);
  };

  /**
   * Returns the new sort direction based on the current sort direction
   * @param {string} sortDir - The current sort direction (asc, desc, '')
   * @returns {string} The new sort direction
   */
  const getNewSortDirection = (sortDir) => {
    // desc -> asc -> '' -> desc
    switch (sortDir) {
      case '':
        return 'desc';
      case 'desc':
        return 'asc';
      case 'asc':
        return '';
      default:
        return '';
    }
  };

  /**
   * Handles the click on the table heading
   * @param {Object} event - The click event object that is used to determine the id of the clicked heading
   */
  const handleTableHeadingClick = (event) => {
    const { id } = event.currentTarget;
    switch (id) {
      case 'name': {
        const newDir = getNewSortDirection(sortName);
        setSortName(newDir);
        // Reset other sort directions
        setSortLastModified('');
        setSortStatus('');
        setSortDocumentTagValue('');
        setSortDocumentType('');
        const sort = {
          sortNameDir: newDir,
          sortLastModifiedDir: null,
          sortStatusDir: null,
          sortDocumentTagValueDir: null,
        };
        fetchDocumentsPaged({ sort });
        break;
      }
      case 'lastModified': {
        const newDir = getNewSortDirection(sortLastModified);
        setSortLastModified(newDir);
        // Reset other sort directions
        setSortName('');
        setSortStatus('');
        setSortDocumentTagValue('');
        setSortDocumentType('');
        const sort = {
          sortLastModifiedDir: newDir,
          sortNameDir: null,
          sortStatusDir: null,
          sortDocumentTagValueDir: null,
        };
        fetchDocumentsPaged({ sort });
        break;
      }
      case 'status': {
        const newDir = getNewSortDirection(sortStatus);
        setSortStatus(newDir);
        // Reset other sort directions
        setSortName('');
        setSortLastModified('');
        setSortDocumentTagValue('');
        setSortDocumentType('');
        const sort = {
          sortStatusDir: newDir,
          sortNameDir: null,
          sortLastModifiedDir: null,
          sortDocumentTagValueDir: null,
        };
        fetchDocumentsPaged({ sort });
        break;
      }
      case 'documentTags': {
        // Disable sorting for the time being
        /* const newDir = getNewSortDirection(sortDocumentTagValue);
        setSortDocumentTagValue(newDir);
        // Reset other sort directions
        setSortName('');
        setSortLastModified('');
        setSortStatus('');
        setSortDocumentTagValue('');
        const sort = {
          sortDocumentTagValueDir: newDir,
          sortStatusDir: null,
          sortNameDir: null,
          sortLastModifiedDir: null,
        };
        fetchDocumentsPaged({ sort }); */
        break;
      }
      case 'documentType': {
        const newDir = getNewSortDirection(sortDocumentType);
        setSortDocumentType(newDir);
        // Reset other sort directions
        setSortName('');
        setSortStatus('');
        setSortLastModified('');
        setSortDocumentTagValue('');
        const sort = {
          sortDocumentTypeDir: newDir,
          sortStatusDir: null,
          sortNameDir: null,
          sortLastModifiedDir: null,
          sortDocumentTagValueDir: null,
        };
        fetchDocumentsPaged({ sort });
        break;
      }
      default:
        break;
    }
  };

  /**
   * Renders the value of the document tag column
   * @param {Object[]} documentTags The document tags of the document
   * @returns {string} The value of the document tag column or '-' if the document tags are undefined or empty
   */
  const renderDocumentTagColumnValue = (documentTags) => {
    if (documentTags === undefined) {
      return '-';
    }
    if (documentTags.length === 0) {
      return '-';
    }
    return `${documentTags[0].key} ${documentTags[0].value}`;
  };

  const getDocumentIcon = (documentType) => {
    const iconMap = {
      pdf: <BsFiletypePdf />,
      doc: <BsFiletypeDoc />,
      docx: <BsFiletypeDocx />,
      image: <BsCardImage />,
    };

    return iconMap[documentType.toLowerCase()] || iconMap.default;
  };

  return (
    <div className="flow-root">
      <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="bg-white px-10 pb-10 pt-5 text-blue-2 shadow ring-1 ring-white/5 sm:rounded-lg">
            <div className="w-full">
              <DocumentTableFilterHeader
                filterDocuments={filterDocuments}
                searchValue={searchValue}
                documentStatusValue={documentStatusValue}
                setDocumentStatusValue={setDocumentStatusValue}
                documentTypeValue={documentTypeValue}
                setDocumentTypeValue={setDocumentTypeValue}
                setSearchValue={setSearchValue}
                setFromDateValue={setFromDateValue}
                setToDateValue={setToDateValue}
                documentTagsWithValues={documentTagsWithValues}
                setDocumentTagsWithValues={setDocumentTagsWithValues}
                setDocumentTagValue={setDocumentTagValue}
                placeholderDocumentTag={placeholderDocumentTag}
              />
            </div>
            <table className="min-w-full">
              <thead className="border-b border-blue-2/30">
                <tr>
                  <th scope="col" className="relative px-3 sm:w-12 sm:px-6">
                    <input
                      type="checkbox"
                      className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-grey-1 text-blue-1"
                      ref={checkbox}
                      checked={areAllDocumentsSelected()}
                      onChange={toggleAll}
                    />
                  </th>
                  <th scope="col" className="relative px-3 sm:w-12 sm:px-6" />
                  <th scope="col" className="w-[45%] px-3 py-3.5 text-left text-lg">
                    <SortableTableHeading
                      text={t('documentSelection.header.name')}
                      id="name"
                      onClick={handleTableHeadingClick}
                      arrowType={sortName}
                    />
                  </th>
                  <th scope="col" className="w-[20%] px-3 py-3.5 text-left text-lg">
                    <SortableTableHeading
                      text={t('documentSelection.header.lastModified')}
                      id="lastModified"
                      onClick={handleTableHeadingClick}
                      arrowType={sortLastModified}
                    />
                  </th>
                  {documentTagsWithValues && documentTagsWithValues.length > 0 ? (
                    <th scope="col" className="w-[20%] px-3 py-3.5 text-left text-lg">
                      <SortableTableHeading
                        text={placeholderDocumentTag}
                        id="documentTags"
                        onClick={handleTableHeadingClick}
                        arrowType={sortDocumentTagValue}
                        disableSorting // Disabled sorting for the time being
                      />
                    </th>
                  ) : null}
                  <th scope="col" className="w-[15%] px-3 py-3.5 text-left text-lg">
                    <SortableTableHeading
                      text={t('documentSelection.header.status')}
                      id="status"
                      onClick={handleTableHeadingClick}
                      arrowType={sortStatus}
                    />
                  </th>
                  <th scope="col" className="w-[15%] px-3 py-3.5 text-right text-lg">
                    {' '}
                  </th>
                </tr>
              </thead>
              {isLoadingDocuments === false ? (
                <tbody>
                  {projectDocuments.map((document) => (
                    <tr
                      key={document.id}
                      className={classNames(
                        `${isDocumentSelected(document.id) ? 'bg-babyBlue-7' : null} 
                         hover:bg-grey-4 `,
                      )}
                    >
                      <td className="relative px-3 sm:w-12 sm:px-6">
                        {isDocumentSelected(document.id) && (
                          <div className="absolute inset-y-0 left-0 w-0.5" />
                        )}
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-grey-1 text-blue-1"
                          value={document.id}
                          checked={isDocumentSelected(document.id)}
                          onChange={(event) =>
                            onChangeDocumentSelection(document.id, event.target.checked)
                          }
                        />
                      </td>
                      <td className="relative px-3 sm:w-12 sm:px-6">
                        {getDocumentIcon(document.documentType)}
                      </td>
                      <td
                        className={classNames(
                          `${isDocumentArchived(document.processingStatus) ? 'text-grey-1' : null}
                          ${
                            isDocumentSelected(document.id) ? 'text-indigo-600' : 'text-gray-900'
                          } whitespace-nowrap py-4 px-3 text-base font-medium text-left `,
                        )}
                      >
                        {truncateText(document.original, 50)}
                      </td>
                      <td
                        className={classNames(
                          `${
                            isDocumentArchived(document.processingStatus) ? 'text-grey-1' : null
                          } w-px whitespace-nowrap px-3 py-4 text-left text-base`,
                        )}
                      >
                        {createHumanReadableRepresentationDate(document.lastModified)}
                      </td>
                      {documentTagsWithValues && documentTagsWithValues.length > 0 ? (
                        <td
                          className={classNames(`${
                            isDocumentArchived(document.processingStatus) ? 'text-grey-1' : null
                          }
                        ${
                          isDocumentSelected(document.id) ? 'text-indigo-600' : 'text-gray-900'
                        } w-px whitespace-nowrap px-3 py-4 text-left text-base`)}
                        >
                          {renderDocumentTagColumnValue(document.tags)}
                        </td>
                      ) : null}
                      <td
                        className={classNames(
                          'whitespace-nowrap px-3 py-4 text-left text-base',
                          document.processingStatus === 'FINALIZED' ? 'text-green-2' : null,
                          document.processingStatus === 'COMPLETED' ? 'text-green-2' : null,
                          document.processingStatus === 'PROCESSING' ? 'text-blue-2' : null,
                          document.processingStatus === 'UNPROCESSED' ? 'text-blue-2' : null,
                          document.processingStatus === 'ERROR' ? 'text-red-1' : null,
                          document.processingStatus === 'PREVIEW_ERROR' ? 'text-red-1' : null,
                          document.processingStatus === 'ARCHIVED' ? 'text-grey-1' : null,
                        )}
                      >
                        {t(`documentSelection.status.${document.processingStatus}`)}
                      </td>
                      <td>
                        <div className="flex items-center justify-end gap-x-2 pr-3">
                          {(() => {
                            if (!isProjectStoringPII) {
                              return [
                                <IconButton
                                  icon={<PreviewIcon />}
                                  color="green"
                                  disabled={document.processingStatus !== 'COMPLETED'}
                                  buttonAction={() => {
                                    showDocumentPreview(document.id);
                                  }}
                                />,
                                <IconButton
                                  icon={<DownloadIcon />}
                                  disabled={document.processingStatus !== 'COMPLETED'}
                                  buttonAction={() => {
                                    onDownload(document.id);
                                  }}
                                />,
                              ];
                            }
                            if (document.processingStatus === 'FINALIZED') {
                              return (
                                <IconButton
                                  icon={<LockIcon />}
                                  color="grey"
                                  buttonAction={() => {
                                    unfinalizeDocument(document.id);
                                  }}
                                />
                              );
                            }
                            return (
                              <IconButton
                                icon={<EditorIcon />}
                                color="green"
                                disabled={document.processingStatus !== 'COMPLETED'}
                                buttonAction={() => {
                                  openEditor(document.id);
                                }}
                              />
                            );
                          })()}
                          {isDocumentSummaryEnabled ? (
                            <IconButton
                              icon={<SummaryIcon />}
                              color="violet"
                              buttonAction={() => {
                                openSummary(projectId, document.id);
                                setDocumentIdToSummarize(document.id);
                                setIsDocumentSummaryOpen(true);
                              }}
                              disabled={
                                document.processingStatus !== 'FINALIZED' &&
                                document.processingStatus !== 'COMPLETED'
                              }
                            />
                          ) : null}
                          <MenuButton
                            menuItems={getQuickActions(document)}
                            menuPosition="right"
                            button={<IconButton icon={<MoreIcon />} />}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={5}>
                      <div className="flex items-center justify-center">
                        <LoadingPage />
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
            {isLoadingDocuments === false ? (
              <>
                <CustomDialog
                  isOpen={isDocumentSummaryOpen}
                  onClose={closeDialog}
                  isDialogCloseButton={false}
                >
                  <DocumentSummaryDialogContent
                    title={
                      isSummaryLoading
                        ? `${t(
                            'documentSelection.documentSummary.loading.titleFirstPart',
                          )} ${documentIdToSummarize} ${t(
                            'documentSelection.documentSummary.loading.titleSecondPart',
                          )}`
                        : `${t(
                            'documentSelection.documentSummary.loadingFinished.title',
                          )} ${documentIdToSummarize}`
                    }
                    subtitle={
                      isSummaryLoading
                        ? t('documentSelection.documentSummary.loading.subtitle')
                        : t('documentSelection.documentSummary.loadingFinished.subtitle')
                    }
                    buttonText={
                      isSummaryLoading
                        ? t('documentSelection.documentSummary.loading.buttonText')
                        : t('documentSelection.documentSummary.loadingFinished.buttonText')
                    }
                    buttonAction={closeDialog}
                    content={isSummaryLoading ? null : documentSummary.summary}
                    isLoading={isSummaryLoading}
                  />
                </CustomDialog>
                <TablePaginationFooter
                  isFirstPage={isFirstPage}
                  isLastPage={totalElements === documentsOffset + projectDocuments.length}
                  pageNumber={currentPage + 1}
                  from={documentsOffset + 1}
                  to={documentsOffset + projectDocuments.length}
                  totalElements={totalElements}
                  onChangePage={(page) => {
                    changePage(page - 1);
                  }}
                  isLoading={isLoadingDocuments}
                />
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentTable;
