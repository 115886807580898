import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Grid,
  GridItem,
  HStack,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Select,
  Spacer,
  Spinner,
  Text,
  theme,
  Tooltip,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BiHide, BiRedo, BiShowAlt, BiUndo } from 'react-icons/bi';
import { BsFileEarmarkCheck } from 'react-icons/bs';
import { FaChevronLeft, FaChevronRight, FaMinus, FaPlus } from 'react-icons/fa';
import { FiDownload, FiSave } from 'react-icons/fi';
import { VscChromeClose } from 'react-icons/vsc';
import ActionPopover from '../../molecules/ActionPopover';
import AppToaster from '../../molecules/AppToaster';
// import { autofillPseudonyms } from '../js/slices/annotationsSlice';

// import {
//   selectDocumentFinalizing,
//   setDocumentFinalizing,
// } from '../js/slices/documentFinalizingSlice';
// import { selectDocumentSaving, setDocumentSaving } from '../js/slices/documentSavingSlice';
// import { downloadAnonymizedDocument } from '../js/slices/documentsSlice';
// import { selectParagraphs } from '../js/slices/paragraphsSlice';

import { createHumanReadableRepresentationDate } from '../../../services/utils';
import SmallIconButton from '../../molecules/SmallIconButton';

export const menuHeight = `(2rem + 1*${theme.space[2]})`; // Button height + 2*2*padding

const TextAnnotationTopMenu = ({
  isPreviewLoading,
  // onFinalize,
  onCloseDocument,
  pageNavigationInstance,
  zoomInstance,
  selectedParagraphIndex,
  setSelectedParagraphIndex,
  paragraphs,
  isSaving,
  isFinalizing,
  finalizeDocument,
  // isFinalizing,
  // setIsFinalizing,
  // isDownloading,
  // setIsDownloading,
  isDownloadPopOverOpen,
  setIsDownloadPopOverOpen,
  isFinalizingPopOverOpen,
  setIsFinalizingPopOverOpen,
  downloadAnonymizedDocument,
  onSaveDocument,
  setIsCompiling,
  isDisplayingPseudonyms,
  setIsDisplayingPseudonyms,
  undo,
  redo,
  undoActionName,
  redoActionName,
  documentName,
  documentLastModified,
}) => {
  const initRef = React.useRef();
  const { t } = useTranslation();
  // const dispatch = useDispatch();
  // const displayPseudonyms = useSelector(selectDisplayPseudonyms);
  // const hasCrIds = useSelector(selectHasCrIds);

  const { CurrentScale, ZoomIn, ZoomOut } = zoomInstance;

  const onChangeParagraph = (newParagraphIdx) => {
    // dispatch(clearSelectedAnnotation()); TODO: clear
    setSelectedParagraphIndex(newParagraphIdx);
  };

  const onDownload = async () => {
    setIsDownloadPopOverOpen(false);
    try {
      await downloadAnonymizedDocument();
    } catch (error) {
      AppToaster({
        description: t('documentSelection.downloadFailed'),
        status: 'error',
      });
    }
  };

  const onToggleDisplayPseudonyms = () => {
    setIsDisplayingPseudonyms(!isDisplayingPseudonyms);
  };

  /**
   * Creates crIds and pseudonyms when clicking the autofill button
   */
  // const onAutofillPseudonyms = () => {
  //   dispatch(autofillPseudonyms());
  //   dispatch(setHasCrIds(true));
  // };

  return (
    <Box width="100%" height={`calc(${menuHeight})`}>
      <Grid
        templateColumns="repeat(2, 1fr)"
        templateRows="repeat(1, 1fr)"
        gap="2"
        padding="2"
        bg="gray.300"
      >
        <GridItem>
          <Flex>
            <HStack>
              <Tooltip
                label={
                  isDisplayingPseudonyms
                    ? t('mainMenu.hidePseudonyms')
                    : t('mainMenu.displayPseudonyms')
                }
              >
                <Box>
                  <SmallIconButton
                    onClick={onToggleDisplayPseudonyms}
                    disabled={false} // TODO: only disable during first loading of textData
                    icon={isDisplayingPseudonyms ? <BiShowAlt size={19} /> : <BiHide size={19} />}
                  />
                </Box>
              </Tooltip>
              <Tooltip
                label={
                  undoActionName
                    ? `${t('mainMenu.undo')}: ${t(`mainMenu.undoRedoActions.${undoActionName}`)}`
                    : t('mainMenu.undoRedoActions.undoNotPossible')
                }
              >
                <Box>
                  <SmallIconButton
                    disabled={!undoActionName}
                    onClick={undo}
                    icon={<BiUndo size={20} />}
                  />
                </Box>
              </Tooltip>
              <Tooltip
                label={
                  redoActionName
                    ? `${t('mainMenu.redo')}: ${t(`mainMenu.undoRedoActions.${redoActionName}`)}`
                    : t('mainMenu.undoRedoActions.redoNotPossible')
                }
              >
                <Box>
                  <SmallIconButton
                    disabled={!redoActionName}
                    onClick={redo}
                    icon={<BiRedo size={20} />}
                  />
                </Box>
              </Tooltip>
              {/* <Tooltip label={t('mainMenu.autofillTooltip')}>
                <Box>
                  <SmallIconButton
                    onClick={onAutofillPseudonyms}
                    size="xs"
                    disabled={isPreviewLoading}
                    icon={<FaHatWizard />}
                  />
                </Box>
              </Tooltip> */}
            </HStack>
            <Spacer />
            <HStack>
              <HStack>
                <Tooltip
                  label={
                    t('mainMenu.documentModifiedTooltip') +
                    createHumanReadableRepresentationDate(documentLastModified)
                  }
                >
                  <Text fontSize="16px">{documentName}</Text>
                </Tooltip>
              </HStack>
            </HStack>
            <Spacer />
          </Flex>
        </GridItem>
        <GridItem>
          <Flex w="100%" justify="center">
            <Flex w="90%" justify="space-around">
              <HStack maxW="320px">
                <Popover
                  placement="left-start"
                  closeOnBlur
                  isOpen={isDownloadPopOverOpen}
                  initialFocusRef={initRef}
                >
                  <Tooltip label={t('documentSelection.download')}>
                    <PopoverAnchor>
                      <Box display="inline-block">
                        <PopoverTrigger>
                          <SmallIconButton
                            size="sm"
                            disabled={false}
                            icon={<FiDownload size={19} />}
                            onClick={() => setIsDownloadPopOverOpen(true)}
                          />
                        </PopoverTrigger>
                      </Box>
                    </PopoverAnchor>
                  </Tooltip>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton onClick={() => setIsDownloadPopOverOpen(false)} />
                    <PopoverHeader fontSize="16px">
                      {t('documentSelection.downloadPopoverHeader')}
                    </PopoverHeader>
                    <PopoverBody>{t('documentSelection.downloadPopoverContent')}</PopoverBody>
                    <PopoverFooter d="flex" justifyContent="flex-end">
                      <Button
                        ref={initRef}
                        size="sm"
                        onClick={() => onDownload()}
                        colorScheme="green"
                      >
                        Ok
                      </Button>
                    </PopoverFooter>
                  </PopoverContent>
                </Popover>
              </HStack>
              <Spacer />
              <HStack maxW="320px">
                <ZoomOut>
                  {(props) => (
                    <SmallIconButton
                      disabled={isPreviewLoading}
                      onClick={props.onClick}
                      icon={<FaMinus size={11} />}
                    />
                  )}
                </ZoomOut>
                <Text fontSize="14px">
                  <CurrentScale>{(props) => `${Math.round(props.scale * 100)}%`}</CurrentScale>
                </Text>
                <ZoomIn>
                  {(props) => (
                    <SmallIconButton
                      disabled={isPreviewLoading}
                      onClick={props.onClick}
                      icon={<FaPlus size={11} />}
                    />
                  )}
                </ZoomIn>
                <SmallIconButton
                  disabled={selectedParagraphIndex <= 0 || isPreviewLoading}
                  onClick={() => {
                    onChangeParagraph(selectedParagraphIndex - 1);
                    pageNavigationInstance.jumpToPage(selectedParagraphIndex - 1);
                  }}
                  icon={<FaChevronLeft size={11} />}
                />
                <Text fontSize="14px">Seite: </Text>
                <Select
                  disabled={isPreviewLoading}
                  size="xs"
                  width="55px"
                  value={selectedParagraphIndex + 1}
                  onChange={(event) => {
                    pageNavigationInstance.jumpToPage(event.target.value - 1);
                    onChangeParagraph(event.target.value - 1);
                  }}
                >
                  {paragraphs.map((paragraph, paragraphIdx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <option key={`SelectParagraph-${paragraphIdx}`} value={paragraphIdx + 1}>
                      {paragraphIdx + 1}
                    </option>
                  ))}
                </Select>
                <Text fontSize="14px">/ {paragraphs.length > 0 ? paragraphs.length : 1}</Text>
                <SmallIconButton
                  disabled={selectedParagraphIndex >= paragraphs.length - 1 || isPreviewLoading}
                  onClick={() => {
                    onChangeParagraph(selectedParagraphIndex + 1);
                    pageNavigationInstance.jumpToPage(selectedParagraphIndex + 1);
                  }}
                  icon={<FaChevronRight size={11} />}
                />
              </HStack>
              <Spacer />
              <HStack>
                <Tooltip label={t('mainMenu.saveTooltip')}>
                  <Box>
                    <SmallIconButton
                      onClick={() => {
                        onSaveDocument();
                        setIsCompiling(true);
                      }}
                      disabled={isPreviewLoading || isSaving || isFinalizing}
                      icon={isSaving ? <Spinner size="sm" /> : <FiSave size={19} />}
                    />
                  </Box>
                </Tooltip>
                <Popover isOpen={isFinalizingPopOverOpen}>
                  <PopoverTrigger>
                    <div>
                      <Tooltip label={t('mainMenu.finalizeTooltip')}>
                        <Box>
                          <SmallIconButton
                            // disabled={isPreviewLoading || isFinalizing}
                            // icon={isFinalizing ? <Spinner size="xs" /> : <BsFileEarmarkCheck />}
                            icon={isFinalizing ? <Spinner size="sm" /> : <BsFileEarmarkCheck />}
                            onClick={() => setIsFinalizingPopOverOpen(true)}
                          />
                        </Box>
                      </Tooltip>
                    </div>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton onClick={() => setIsFinalizingPopOverOpen(false)} />
                    <PopoverHeader fontSize="18px">
                      {t('mainMenu.finalizePopoverHeader')}
                    </PopoverHeader>
                    <PopoverBody>{t('mainMenu.finalizePopoverBody')}</PopoverBody>
                    <PopoverFooter display="flex" justifyContent="flex-end">
                      <ButtonGroup size="sm">
                        <Button
                          colorScheme="green"
                          isDisabled={isFinalizing || isSaving}
                          onClick={async () => {
                            setIsFinalizingPopOverOpen(false);
                            finalizeDocument();
                          }}
                        >
                          {t('mainMenu.finalizePopoverButton')}
                        </Button>
                      </ButtonGroup>
                    </PopoverFooter>
                  </PopoverContent>
                </Popover>
                <ActionPopover
                  isLoading={isPreviewLoading}
                  triggerButtonIcon={<VscChromeClose />}
                  headerContent={t('mainMenu.closePopoverHeader')}
                  bodyContent={t('mainMenu.closePopoverBody')}
                  closeButtonContent={t('mainMenu.closeButton')}
                  onClose={onCloseDocument}
                  tooltip={t('mainMenu.closeTooltip')}
                  actionButtonContent={t('mainMenu.saveAndCloseButton')}
                  onAction={() => {
                    onSaveDocument();
                    onCloseDocument();
                  }}
                />
              </HStack>
            </Flex>
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};

TextAnnotationTopMenu.propTypes = {
  isPreviewLoading: PropTypes.bool.isRequired,
  // onCompile: PropTypes.func.isRequired,
  // onFinalize: PropTypes.func.isRequired,
};

export default TextAnnotationTopMenu;
