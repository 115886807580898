import React from 'react';

/**
 * ImagePreview component renders a preview of the image.
 *
 * @param {string} documentBase64 The base64 string of the image.
 * @param {boolean} isDocumentLoading Flag indicating if the image is loading.
 */
const ImagePreview = ({ imageBase64, isImageLoading }) => {
  const renderImagePreview = () => {
    if (!isImageLoading && imageBase64) {
      return (
        <div className="mt-5 h-full w-full">
          <img
            src={`data:image/jpeg;base64,${imageBase64}`}
            alt="Document"
            className="object-contain"
          />
        </div>
      );
    }
    return (
      <div className="loader-container-small mt-5">
        <div className="loader" />
      </div>
    );
  };

  return <>{renderImagePreview()}</>;
};

export default ImagePreview;
