import { Alert, AlertIcon, Box, Center, Spinner } from '@chakra-ui/react';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useLayout from '../../../hooks/useLayout';
import useSize from '../../../hooks/useSize';
import VScrollable from '../../molecules/VScrollable';
import PdfPreview from './PdfPreview';

const PdfPreviewController = ({
  isCompiling,
  didCompilationFail,
  anonymizedDocumentBase64,
  isAnonymizedBase64Loading,
  originalDocumentBase64,
  isOriginalBase64Loading,
  isDocumentOutdated,
  zoomInstance,
  scrollModeInstance,
  pageNavigationInstance,
  isDisplayingPseudonyms,
  selectedParagraphIndex,
}) => {
  const { contentHeight, mainMenuHeight } = useLayout();

  const { t } = useTranslation();
  const targetRef = useRef();
  const boxSize = useSize(targetRef);

  /**
   * Renders an alert if the PDF preview is still compiling, if the compilation failed
   * or if the pdf is outdated.
   * @returns {JSX.Element} The alert.
   */
  const renderPDFAlert = () => {
    if (isCompiling) {
      return (
        <Alert status="warning" intent="warning">
          <AlertIcon />
          {t('preview.warning')}
        </Alert>
      );
    }
    if (didCompilationFail) {
      return (
        <Alert status="error" intent="danger">
          <AlertIcon />
          {t('preview.error')}
        </Alert>
      );
    }
    if (isDocumentOutdated) {
      return (
        <Alert status="info" intent="info">
          <AlertIcon />
          {t('preview.outdated')}
        </Alert>
      );
    }
    return null;
  };

  const renderDocument = (base64Pdf, isBase64Loading) => {
    if (!isBase64Loading && base64Pdf && boxSize) {
      return (
        <PdfPreview
          selectedParagraphIndex={selectedParagraphIndex}
          base64Pdf={base64Pdf}
          parentSize={boxSize}
          zoomInstance={zoomInstance}
          scrollModeInstance={scrollModeInstance}
          pageNavigationInstance={pageNavigationInstance}
        />
      );
    }
    return (
      <Center p="20%">
        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
      </Center>
    );
  };

  return (
    <Box bg="white" rounded={5} padding={2} width="100%" height="100%" ref={targetRef}>
      {renderPDFAlert()}
      <VScrollable
        height={
          isCompiling || didCompilationFail
            ? `calc(${contentHeight} - ${mainMenuHeight} - 104px)` // Warning height is 72px => 32px + 72px = 104px TODO: Compute warning height dynamically
            : `calc(${contentHeight} - ${mainMenuHeight} - 32px)` // 32px is the height of card margin and card padding (determined through trial and error)
        }
        overflowY="auto"
      >
        <Center>
          {isDisplayingPseudonyms
            ? renderDocument(anonymizedDocumentBase64, isAnonymizedBase64Loading)
            : renderDocument(originalDocumentBase64, isOriginalBase64Loading)}
        </Center>
      </VScrollable>
    </Box>
  );
};

export default PdfPreviewController;
