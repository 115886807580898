import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Displays a link so that the user can configure cookies settings
 */
const IubendaCookieSettingsLink = () => {
    const { t } = useTranslation();

    return (
        <a
            href="#"
            className="iubenda-cs-preferences-link inline-block align-middle text-base uppercase text-blue-2 hover:text-blue-1"
        >
            {t('footer.cookieSettings')}
        </a>
    );
};

export default IubendaCookieSettingsLink;
