import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getDocumentData, putDocumentData } from '../api/document';

const initialState = {
  data: {
    documentId: 0,
    version: 0,
    processingStatus: '',
    pageData: [
      {
        pageNum: 0,
        textData: {},
        imagesData: [
          {
            imageUrl: 'string',
            xref: 0,
            detections: {},
          },
        ],
      },
    ],
  },
  status: '',
  error: '',
};

export const fetchDocumentData = createAsyncThunk(
  'fetch/documentData',
  async ({ projectId, documentId }) => {
    const response = await getDocumentData(projectId, documentId);
    return response.data;
  },
);

export const updateDocumentData = createAsyncThunk(
  'update/documentData',
  async ({ projectId, documentId, documentData }) => {
    const response = await putDocumentData(projectId, documentId, documentData);
    return response.data;
  },
);

/**
 * The slice of the document data
 */
export const documentDataSlice = createSlice({
  name: 'documentData',
  initialState,
  reducers: {
    /**
     * Clears the document data state by setting it to the initial state
     */
    clearDocumentData() {
      return initialState;
    },
    updateVersion(state, action) {
      state.data.version = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDocumentData.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchDocumentData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchDocumentData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateDocumentData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Because the state of the document data is already held in the hook
        // the only attribute that needs to be updated is the version
        // If this is not done the backend will send a 409 error
        state.data.version = action.payload.version;
      });
  },
});

export const selectDocumentData = createSelector(
  (state) => state.documentData,
  (documentData) => documentData.data,
);
export const selectProcessingStatus = createSelector(
  (state) => state.documentData.data,
  (data) => data.processingStatus,
);
export const selectPageData = createSelector(
  (state) => state.documentData.data,
  (data) => data.pageData,
);
export const selectDocumentDataStatus = createSelector(
  (state) => state.documentData,
  (documentData) => documentData.status,
);
export const selectDocumentDataError = createSelector(
  (state) => state.documentData,
  (documentData) => documentData.error,
);
export const selectDocumentType = createSelector(
  (state) => state.documentData.data,
  (data) => data.documentType,
);
export const selectDocumentName = createSelector(
  (state) => state.documentData.data,
  (data) => data.documentName,
);
export const selectLastModified = createSelector(
  (state) => state.documentData.data,
  (data) => data.lastModified,
);
export const selectLastFileModified = createSelector(
  (state) => state.documentData.data,
  (data) => data.lastFileModified,
);

export const { clearDocumentData, updateVersion } = documentDataSlice.actions;

export default documentDataSlice.reducer;
