import React from 'react';
import { classNames } from '../../../utils/tailwindUtils';

/**
 * Displays a button containing text and an icon with one of the defined color styles
 * @param {string} buttonText - The text inside the button default null
 * @param {JSX.Element} icon - The icon to display default null
 * @param {function} buttonAction - The action to perform when the button is clicked
 * @param {string} color - The color of the button (babyBlue, blue, blueNav, green, red, violet, white)
 * @param {boolean} disabled - If the button is disabled default false
 * @param {boolean} useReactIcons - If the button should use React Icons default false (if false, provide custom icons)
 * @param {string} iconSize - The size of the icon (small, medium, large) default medium
 */
const IconButton = ({
  buttonText = null,
  icon = null,
  buttonAction,
  color = 'blue',
  disabled = false,
  useReactIcons = false,
  iconSize='medium',
  ...rest
}) => {
  const colorVariants = {
    babyBlue:
      'bg-babyBlue-1 text-white hover:bg-white border-babyBlue-1 hover:border-babyBlue-1 hover:text-babyBlue-1 focus-visible:outline-babyBlue-1',
    blue: 'bg-blue-3 text-white hover:bg-white border-blue-3 hover:border-blue-3 hover:text-blue-3 focus-visible:outline-blue-3',
    blueNav:
      'bg-blue-2 text-white  hover:bg-white border-white hover:border-blue-2 hover:text-blue- focus-visible:outline-blue-2',
    violet:
      'bg-violet-1 text-white hover:bg-white border-violet-1 hover:border-violet-1 hover:text-violet-1 focus-visible:outline-violet-1',
    white:
      'bg-white text-blue-3 hover:bg-blue-3 border-babyBlue-1 hover:border-blue-3 hover:text-white focus-visible:outline-white',
    green:
      'bg-green-2 text-white hover:bg-white border-green-2 hover:border-green-2 hover:text-green-2 focus-visible:outline-green-2',
    red: 'bg-red-3 text-white hover:bg-white border-red-3 hover:border-red-3 hover:text-red-3 focus-visible:outline-red-3',
    grey: 'bg-grey-1 text-white hover:bg-white border-grey-1 hover:border-grey-1 hover:text-grey-1 focus-visible:outline-grey-1',
  };

  const disabledColorVariants = {
    green: 'bg-green-1 text-white border-green-1 hover:cursor-not-allowed',
    red: 'bg-red-5 text-white border-red-5 hover:cursor-not-allowed',
    blue: 'bg-babyBlue-4 text-white border-babyBlue-4 hover:cursor-not-allowed',
    violet: 'bg-violet-2 text-white border-violet-2 hover:cursor-not-allowed',
  };

  const iconSizeVariants = {
    small: 'h-6 w-6',
    medium: 'h-8 w-8',
    large: 'h-10 w-10',
  };

  if (useReactIcons) {
    return (
      <button
        type="button"
        className={classNames(
          `${disabled ? disabledColorVariants[color] : colorVariants[color]
          } max-h-9 shadow-sm border rounded-xl leading-2 hover:border-1 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${buttonText ? 'pr-2.5' : null
          } ${icon ? null : 'py-2.5 px-2.5'}`,
        )}
        disabled={disabled}
        onClick={buttonAction}
        {...rest}
      >
        <div className="flex items-center justify-around text-sm">
          {icon && (
            <div className={classNames(`${iconSizeVariants[iconSize]}`, 'text-current')}>
              {icon}
            </div>
          )}
          {buttonText && <div className="ml-2">{buttonText}</div>}
        </div>
      </button>
    );
  }

  return (
    <button
      type="button"
      className={classNames(
        `${disabled ? disabledColorVariants[color] : colorVariants[color]
        } max-h-9 shadow-sm border rounded-xl leading-2 hover:border-1 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${buttonText ? 'pr-2.5' : null
        } ${icon ? null : 'py-2.5 px-2.5'}`,
      )}
      disabled={disabled}
      onClick={buttonAction}
      {...rest}
    >
      <div className="flex items-center justify-around text-sm">
        <div>{icon}</div>
        <div>{buttonText}</div>
      </div>
    </button>
  );
};

export default IconButton;
