import { ChakraProvider } from '@chakra-ui/react';
import { MatomoProvider } from '@datapunt/matomo-tracker-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import { store } from './reducers/store';
import { setupInterceptors } from './services/apiService';
import { matomotInstance } from './utils/matomoUtils';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <MatomoProvider value={matomotInstance}>
      <ChakraProvider>
        {/*
          This "Hack" was necessary to overwrite the styles in the chakra provider
          since i was unable to remove the default font family of chakra
          after removal or the chakra provider the following div can be removed as well
         */}
        <div className="font-futura">
          <HashRouter>
            <App />
          </HashRouter>
        </div>
      </ChakraProvider>
      </MatomoProvider>
    </Provider>
  </React.StrictMode>,
);

setupInterceptors(store);
