// Utils file for Matomo tracking (https://matomo.org/get/matomo-analytics-de-2p/)
// Matomo settings are managed here: https://matomo.cloud.doccape.de/
import { createInstance } from "@datapunt/matomo-tracker-react";

/**
 * Matomo instance for tracking.
 * Configures the Matomo instance with the Matomo URL, site ID, tracker URL, source URL, and configurations.
 */
export const matomotInstance = createInstance({
 urlBase: 'https://matomo.cloud.doccape.de/', 
  siteId: 7,
  trackerUrl: 'https://matomo.cloud.doccape.de//matomo.php',
  srcUrl: 'https://matomo.cloud.doccape.de/matomo.js',
  disabled: false,
  heartBeat: {
    active: true,
    seconds: 10
  },
  linkTracking: true,
  configurations: {
    disableCookies: true
  } 
});

