import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomDialog from '../../../molecules/tailwind/CustomDialog';
import CustomDialogContent from '../../../molecules/tailwind/CustomDialogContent';
import CustomForm from '../../../molecules/tailwind/CustomForm';

const CreateProjectDialog = ({
  open,
  setOpen,
  onAddProject,
  isValidProjectName,
  projectNameError,
  errorMsg,
  setErrorMsg,
  setIsValidProjectName,
  setProjectNameError,
  isLoadingCreateProject,
}) => {
  const { t } = useTranslation();
  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [isNotStoringPII, setIsNotStoringPII] = useState(false);

  const handleProjectDescription = (description) => {
    setProjectDescription(description);
  };

  const resetModalData = () => {
    setOpen(false);
    setProjectName('');
    setProjectDescription('');
    setErrorMsg('');
    setIsValidProjectName(true);
    setProjectNameError(false);
    setIsNotStoringPII(false);
  };

  const closeModalWithoutSaving = () => {
    resetModalData();
  };

  const createProject = async () => {
    const isSuccessful = await onAddProject({
      name: projectName,
      description: projectDescription,
      isStoringPII: !isNotStoringPII,
    });
    return isSuccessful;
  };

  const handleProjectName = (name) => {
    if (name !== '') {
      setProjectNameError(false);
      setIsValidProjectName(true);
      setProjectName(name);
    } else {
      setErrorMsg(t('projectMngmt.overview.createProjectModal.errorsMsg.projectNameMustNotEmpty'));
      setOpen(true);
      setProjectNameError(true);
      setIsValidProjectName(false);
      setProjectName('');
    }
  };

  const handleNotValidUserInput = () => {
    setErrorMsg(t('projectMngmt.overview.createProjectModal.errorsMsg.projectAlreadyExists'));
    setOpen(true);
    setProjectNameError(true);
    setIsValidProjectName(false);
  };

  const closeAndSaveModal = async () => {
    if (projectName !== '') {
      const isSuccesful = await createProject();
      if (isSuccesful) {
        resetModalData();
      } else {
        handleNotValidUserInput();
      }
    } else {
      setErrorMsg(t('projectMngmt.overview.createProjectModal.errorsMsg.projectNameMustNotEmpty'));
      setIsValidProjectName(false);
      setProjectNameError(true);
    }
  };

  /**
   * Input fields for the create project dialog
   */
  const createProjectInputFields = [
    {
      id: 'projectName',
      name: t('projectMngmt.overview.createProjectModal.projectName'),
      type: 'text',
      value: projectName,
      onChange: (event) => handleProjectName(event.target.value),
      valid: isValidProjectName && !projectNameError,
      errorMsg,
      required: true,
    },
    {
      id: 'projectDescription',
      name: t('projectMngmt.overview.createProjectModal.projectDescription'),
      type: 'textarea',
      value: projectDescription,
      onChange: (event) => handleProjectDescription(event.target.value),
      required: false,
    },
    {
      id: 'doNotStorePII',
      name: t('projectMngmt.overview.createProjectModal.doNotStorePII'),
      type: 'checkbox',
      value: isNotStoringPII,
      onChange: (event) => setIsNotStoringPII(event.target.checked),
      hintMsg: t('projectMngmt.overview.createProjectModal.doNotStorePIIHint'),
      showHint: !isNotStoringPII,
      required: false,
    },
  ];

  return (
    <CustomDialog isOpen={open} onClose={closeModalWithoutSaving}>
      <CustomDialogContent
        title={t('projectMngmt.overview.createProjectModal.title')}
        subtitle={t('projectMngmt.overview.createProjectModal.subtitle')}
        buttonText={t('projectMngmt.overview.createProjectModal.submit')}
        buttonAction={closeAndSaveModal}
        content={<CustomForm inputFields={createProjectInputFields} />}
        isLoading={isLoadingCreateProject}
      />
    </CustomDialog>
  );
};

export default CreateProjectDialog;
