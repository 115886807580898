import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { scrollModePlugin } from '@react-pdf-viewer/scroll-mode';
import { zoomPlugin } from '@react-pdf-viewer/zoom';

// TODO implement general download functionality
export const createDownloadFromResponse = (documentIds) => {};

export const acceptedExtensions = [
  '.pdf',
  '.docx',
  '.doc',
  '.jpg',
  '.jpeg',
  '.tif',
  '.tiff',
  '.bmp',
  '.png',
];

/**
 * Generates a string that represents the sort configuration
 * The backend expects an array of strings with the format "name, direction"
 * @param {Array} sortConfig - An array of objects with the format { name: "name", direction: "direction" }
 * @returns
 */
export const generateSortArray = (sortConfig) => {
  const sortArray = [];
  sortConfig.forEach((sort) => {
    if (sort.direction === '' || sort.direction === null) {
      return;
    }
    const sortValue = `${sort.name},${sort.direction}`;
    sortArray.push(sortValue);
  });
  return sortArray;
};

/**
 * Create Instances of used plugins
 * @returns Instances of used plugins
 */
export const generatePluginInstances = () => {
  const zoomInstance = zoomPlugin();
  const scrollModeInstance = scrollModePlugin();
  const pageNavigationInstance = pageNavigationPlugin();
  return { zoomInstance, pageNavigationInstance, scrollModeInstance };
};

/**
 * Changes the `processingStatus` of a document with the given `documentId` in the `documents` array
 * @param {Object[]} documents The documents array
 * @param {number} documentId The id of the document to change the processing status of
 * @param {string} processingStatus The new processing status
 * @returns The updated documents array or the original array if the processing status did not change
 */
export const changeProcessingStatusOfDocument = (documents, documentId, processingStatus) => {
  const documentIndex = documents.findIndex((document) => document.id === documentId);
  if (documentIndex === -1) {
    console.warn(`Document with id ${documentId} not found!`);
    return documents;
  }

  let updatedDocument = documents[documentIndex];
  if (updatedDocument.processingStatus === processingStatus) {
    return documents;
  }
  updatedDocument = { ...updatedDocument, processingStatus };

  return [
    ...documents.slice(0, documentIndex),
    updatedDocument,
    ...documents.slice(documentIndex + 1),
  ];
};
